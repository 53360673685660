// import { del, get, post, put } from "./api_helper";
import { post } from "./api_helper";

const API_URL = "https://geolocation-db.com/json/";

const login = async (body) => {
  const url = "api/login";
  let IPAddress = null;
  let data = body;
  await fetch(API_URL)
    .then((res) => res.json())
    .then((json) => {
      IPAddress = json.IPv4;
      data = {
        ...data,
        // password: Encryption.encrypt(data.password),
        password: data.password,
        IP_Address: IPAddress,
      };
    })
    .catch((err) => console.log(err));
  return post(url, data);
};

export default login;
