import React from "react";

const ForgetPassword = () => {
  return (
    <React.Fragment>
        ForgetPassword
    </React.Fragment>
  );
};

export default ForgetPassword;
