import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { TablePagination } from "@mui/material";
import { styled } from "@mui/material/styles";

// Icons
// import { FaCirclePlus } from "react-icons/fa6";
import { FaPen } from "react-icons/fa";
import { MdUpload } from "react-icons/md";

// Form Feilds
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import {
  // createSlider,
  getSlider,
  getSliderType,
  updateSlider,
} from "../../../services/dashboard.service";

function Product() {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [zoomedImages, setZoomedImages] = useState({});

  const toggleZoom = (imageKey) => {
    setZoomedImages((prevState) => ({
      ...prevState,
      [imageKey]: !prevState[imageKey],
    }));
  };

  const [editId, setEditId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    Title: "",
    Desc: "",
    Link: "",
    Image: "",
    Slider_Type_Id: "1",
    IsActive: true,
    IsDelete: false,
    IsEditable: true,
  });

  // const creatProduct = () => {
  //   setCreate(true);
  //   editData();
  // };

  const editData = (Id) => {
    const selectedRow = data.find((data) => data.Slider_Id === Id);
    if (selectedRow) {
      setFormData({
        Title: selectedRow.Title || "",
        Desc: selectedRow.Desc || "",
        Link: selectedRow.Link || "",
        IsActive: selectedRow.IsActive || "",
        Slider_Type_Id: selectedRow.Slider_Type_Id,
        Image: selectedRow.Image,
      });
    }
    setEditId(Id);
    setOpenDialog(true);
  };

  const closeEditForm = () => {
    setChangeImage(false);
    setImagePreview(null);
    setFormData({
      Title: "",
      Desc: "",
      Link: "",
      Image: "",
      Slider_Type_Id: "1",
      IsActive: true,
      IsDelete: false,
      IsEditable: true,
    });
    setEditId(null);
    setOpenDialog(false);
    // setCreate(false);
    setMessageError(false);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: inputValue,
    });
  };

  // const [selectedImage, setSelectedImage] = useState(null);
  const [changeImage, setChangeImage] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (event) => {
    setChangeImage(true);
    const file = event.target.files[0];

    // Display image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
      console.log(reader.result);
      setFormData({
        ...formData,
        Image: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    const inputValue = value;
    setFormData({
      ...formData,
      [name]: inputValue,
    });
  };

  // const [create, setCreate] = useState(false);
  const [messageError, setMessageError] = useState(false);

  // const formDataWithCreatedBy = {
  //   ...formData,
  //   IsEditable: true,
  //   CreatedBy: '1', //Get ID from Local Storage
  // };
  const formDataWithModifyBy = {
    ...formData,
    IsEditable: true,
    ModifyBy: "1", //Get ID from Local Storage
  };

  const handleSubmit = async () => {
    if (formData.Title === "" || formData.Slider_Type_Id === 0) {
      setMessageError(true);
      // } else {
      // if (create) {
      // try {
      //   const response = await createSlider(formDataWithCreatedBy);
      //   const response2 = await getSlider();
      //   const jsonData = await response2;
      //   setData(jsonData);
      //   // if (!response.ok) {
      //   //   throw new Error("Network response was not ok");
      //   // }
      //   console.log(response);
      //   closeEditForm();
      // } catch (error) {
      //   console.error("Error adding slider:", error);
      // }
    } else {
      try {
        // console.log(formDataWithModifyBy)
        const response = await updateSlider(formDataWithModifyBy, editId);
        const response2 = await getSlider();
        const jsonData = await response2;
        setData(jsonData);
        // if (!response.ok) {
        //   throw new Error("Network response was not ok");
        // }
        // console.log(response);
        closeEditForm();
      } catch (error) {
        console.error("Error updating slider:", error);
      }
      setChangeImage(false);
      setImagePreview(null);
      setFormData({
        Title: "",
        Desc: "",
        Link: "",
        Image: "",
        Slider_Type_Id: "1",
        IsActive: true,
        IsDelete: false,
        IsEditable: true,
      });
      setOpenDialog(false);
      // setCreate(false);
      setMessageError(false);
    }
    // }
  };

  const [sliderTypeName, setSliderTypeName] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getSlider();
        // if (!response.ok) {
        //   throw new Error("Network response was not ok");
        // }
        const jsonData = await response;
        setData(jsonData);

        const response2 = await getSliderType();
        // if (!response.ok) {
        //   throw new Error("Network response was not ok");
        // }
        const jsonData2 = await response2;
        setSliderTypeName(jsonData2);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="dashboard-component-container">
      {/* <div className="addButton">
        <Button
          style={{
            border: "1px solid var(--color-primary)",
            color: "var(--color-primary)",
          }}
          variant="outlined"
          startIcon={<FaCirclePlus />}
          onClick={() => creatProduct()}
        >
          Add Slider
        </Button>
      </div> */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">Title</TableCell>
              {/* <TableCell align="center">Description</TableCell> */}
              <TableCell align="center">Image</TableCell>
              <TableCell align="center">Link</TableCell>
              <TableCell align="center">Slider Type Name</TableCell>
              <TableCell align="center">Active</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  key={row.Slider_Id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {row.Slider_Id}
                  </TableCell>
                  <TableCell align="left">{row.Title}</TableCell>
                  {/* <TableCell align="left">{row.Desc}</TableCell> */}
                  <TableCell align="center">
                    {row.Image !== "https://union.kidznteen.pk/public/" ? (
                      <img
                        className={
                          zoomedImages[row.Slider_Id]
                            ? "productTableImage zoomed"
                            : "productTableImage"
                        }
                        onClick={() => toggleZoom(row.Slider_Id)}
                        src={row.Image}
                        alt=""
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row.Link !== null ? row.Link : "-"}
                  </TableCell>
                  <TableCell align="left">{row.Slider_Type_Name}</TableCell>

                  <TableCell align="center">
                    {row.IsActive ? "Active" : "Inactive"}
                  </TableCell>
                  <TableCell align="center">
                    <button
                      className="Edit-Button"
                      onClick={() => editData(row.Slider_Id)}
                    >
                      <FaPen></FaPen>
                    </button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={openDialog} onClose={closeEditForm} className="Dialog">
        <DialogTitle>
          {/* {create ? `Create Slider` : `Edit Slider ID: ${editId}`} */}
          Edit Slider ID: ${editId}
        </DialogTitle>
        <DialogContent className="DialogContent" style={{ overflow: "hidden" }}>
          <form className="form">
            <div className="formGroup">
              <TextField
                id="Title"
                label="Title"
                name="Title"
                value={formData.Title}
                onChange={handleInputChange}
                variant="outlined"
                style={{ marginTop: "5px", width: "100%" }}
              />
              <TextField
                id="Desc"
                label="Desc"
                multiline
                rows={4}
                variant="outlined"
                name="Desc"
                value={formData.Desc}
                onChange={handleInputChange}
                style={{ marginTop: "5px", width: "100%" }}
              />
              <TextField
                id="Link"
                label="Link"
                name="Link"
                value={formData.Link}
                onChange={handleInputChange}
                variant="outlined"
                style={{ marginTop: "5px", width: "100%" }}
              />
              <FormControl fullWidth>
                <InputLabel style={{ marginTop: "5px" }} id="Category">
                  Slider Type Name
                </InputLabel>
                <Select
                  labelId="Slider_Type_Id"
                  id="Slider_Type_Id"
                  label="Slider Type Name"
                  value={formData.Slider_Type_Id}
                  onChange={handleSelectChange}
                  name="Slider_Type_Id"
                  style={{ marginTop: "5px" }}
                >
                  {sliderTypeName.map((item) => (
                    <MenuItem
                      key={item.Slider_Type_Id}
                      value={item.Slider_Type_Id}
                    >
                      {item.Title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControlLabel
                label="Active"
                style={{ marginTop: "5px" }}
                control={
                  <Checkbox
                    inputProps={{ "aria-label": "controlled" }}
                    id="isActive"
                    name="IsActive"
                    checked={formData.IsActive}
                    onChange={handleInputChange}
                  />
                }
              />

              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                onChange={handleImageChange}
                style={{ marginTop: "5px" }}
                startIcon={<MdUpload />}
              >
                Upload Picture
                <VisuallyHiddenInput type="file" />
              </Button>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {changeImage ? (
                  <img
                    src={changeImage ? imagePreview : formData.Image}
                    alt=""
                    style={{
                      width: "100%",
                      maxWidth: "500px",
                      marginTop: "5px",
                      objectFit: "contain",
                      height: "300px",
                    }}
                  />
                ) : (
                  <img
                    src={changeImage ? imagePreview : formData.Image}
                    alt=""
                    style={{
                      width: "100%",
                      maxWidth: "500px",
                      marginTop: "5px",
                      objectFit: "contain",
                      // height: create
                      //   ? "0px"
                      //   : formData.Image !==
                      //     "https://union.kidznteen.pk/public/"
                      //   ? "300px"
                      //   : "0px",
                      height:
                        formData.Image !== "https://union.kidznteen.pk/public/"
                          ? "300px"
                          : "0px",
                    }}
                  />
                )}
              </div>
              {messageError ? (
                <p className="errorMessage">Please enter all the fields</p>
              ) : (
                <p></p>
              )}
            </div>

            <div className="formGroup"></div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditForm}>Cancel</Button>
          {/* <Button onClick={handleSubmit}> {create ? "Create" : "Save"}</Button> */}
          <Button onClick={handleSubmit}> Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Product;
