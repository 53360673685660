import React, { useState, useEffect } from "react";

import Details from "./Details";

// import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";

// Icons
// import { FaCirclePlus } from "react-icons/fa6";

// Form Feilds
// import TextField from "@mui/material/TextField";
// import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
// import InputLabel from "@mui/material/InputLabel";
// import { getSubMenu, createSubMenuItem } from "../../../services/dashboard.service";
import { getSubMenu } from "../../../services/dashboard.service";

function Submenu() {
  const [menuData, setMenuData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [openDialog, setOpenDialog] = useState(false);
  // const [messageError, setMessageError] = useState(false);

  // const [formData, setFormData] = useState({
  //   Menu_Id: 1,
  //   Name: "",
  //   Link: "",
  //   IsActive: false,
  //   IsDelete: false,
  //   IsEditable: true,
  //   CreatedBy: 1,
  // });

  // const createSubMenu = () => {
  //   setOpenDialog(true);
  // };

  const [subMenuData, setSubMenuData] = useState();

  const menuClicked = async (menuName) => {
    // console.log(menuName);
    setSubMenuData(await menuData.find((Menu) => Menu.Menu_Name === menuName));
  };

  // const closeEditForm = () => {
  //   setFormData({
  //     Menu_Id: 1,
  //     Name: "",
  //     Link: "",
  //     IsActive: true,
  //     IsDelete: false,
  //     IsEditable: true,
  //     CreatedBy: 1,
  //   });
  //   setMessageError(false);
  //   setOpenDialog(false);
  // };

  // const handleInputChange = (e) => {
  //   const { name, value, type, checked } = e.target;
  //   const inputValue = type === "checkbox" ? checked : value;
  //   setFormData({
  //     ...formData,
  //     [name]: inputValue,
  //   });
  // };

  // const handleSelectChange = (e) => {
  //   const { name, value } = e.target;
  //   const inputValue = value;
  //   setFormData({
  //     ...formData,
  //     [name]: inputValue,
  //   });
  // };

  // const handleSubmit = async () => {
  //   if (formData.Name === "s") {
  //     setMessageError(true);
  //   } else {
  //     try {
  //       const response = await createSubMenuItem(formData);
  //       // if (!response.ok) {
  //       //   throw new Error("Network response was not ok");
  //       // }
  //       console.log(response);
  //       closeEditForm();
  //     } catch (error) {
  //       console.error("Error adding submenu:", error);
  //     }
  //   }
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getSubMenu();
        // if (!response.ok) {
        //   throw new Error("Network response was not ok");
        // }
        const jsonData = await response;
        setMenuData(jsonData);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <React.Fragment>
      <div className="dashboard-component-container">
        {/* <div className="addButton">
          <Button
            style={{
              border: "1px solid var(--color-primary)",
              color: "var(--color-primary)",
            }}
            variant="outlined"
            startIcon={<FaCirclePlus />}
            onClick={() => createSubMenu()}
          >
            Add Sub Menu
          </Button>
        </div> */}
        <div className="menu-cards">
          {menuData.map((item) => (
            <Button
              variant="outlined"
              size="large"
              className="menu-card"
              style={{
                border: "1px solid var(--color-primary)",
                color: "var(--color-primary)",
                width: "clamp(300px, 100%, 300px)",
                height: "80px",
              }}
              onClick={() => menuClicked(item.Menu_Name)}
              key={item.Menu_Id}
              disabled={!(item.Sub_Menu.length > 0)}
            >
              {item.Menu_Name}
            </Button>
          ))}
        </div>
        {/* <Dialog open={openDialog} onClose={closeEditForm} className="Dialog">
          <DialogTitle>Create Sub Menu</DialogTitle>
          <DialogContent className="DialogContent">
            <form className="form">
              <div className="formGroup">
                <FormControl fullWidth>
                  <InputLabel style={{ marginTop: "5px" }} id="Menu_Id">
                    Select Menu Item
                  </InputLabel>
                  <Select
                    labelId="Menu_Id"
                    id="Menu_Id"
                    label="Select Menu Item"
                    name="Menu_Id"
                    value={formData.Menu_Id}
                    onChange={handleSelectChange}
                    style={{ marginTop: "5px" }}
                  >
                    {menuData.map((item) => (
                      <MenuItem key={item.Menu_Id} value={item.Menu_Id}>
                        {item.Menu_Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  id="Name"
                  label="Sub Menu Name"
                  name="Name"
                  value={formData.Name}
                  onChange={handleInputChange}
                  variant="outlined"
                  style={{ marginTop: "5px", width: "100%" }}
                />
                <TextField
                  id="Link"
                  label="Link"
                  name="Link"
                  value={formData.Link}
                  onChange={handleInputChange}
                  variant="outlined"
                  style={{ marginTop: "5px", width: "100%" }}
                />
                <FormControlLabel
                  label="Active"
                  style={{ marginTop: "5px" }}
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      id="isActive"
                      name="IsActive"
                      checked={formData.IsActive}
                      onChange={handleInputChange}
                    />
                  }
                />
                {messageError ? (
                  <p className="errorMessage">
                    Please enter all the required fields
                  </p>
                ) : (
                  <p></p>
                )}
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeEditForm}>Cancel</Button>
            <Button onClick={handleSubmit}> Create</Button>
          </DialogActions>
        </Dialog> */}
      </div>
      <Details subMenuData={subMenuData}> </Details>
    </React.Fragment>
  );
}

export default Submenu;
