import { post, put, get } from "./api_helper";

// Product Page
const createProduct = async (body) => {
  const url = "api/product_store";
  let data = body;
  return post(url, data);
};

const updateProduct = async (body, id) => {
  const url = `api/product_store/${id}`;
  let data = body;
  return put(url, data);
};

const getProduct = async () => {
  const url = "api/product";
  return get(url);
};

// Category Page
const getCategory = async () => {
  const url = "api/category";
  return get(url);
};

// Category Page
const getForm = async () => {
  const url = "api/form";
  return get(url);
};

// Menu Page
const createMenu = async (body) => {
  const url = "api/menus_store";
  let data = body;
  return post(url, data);
};

const updateMenu = async (body, id) => {
  const url = `api/menus_update/${id}`;
  let data = body;
  return put(url, data);
};
const getMenu = async () => {
  const url = "api/menus";
  return get(url);
};

// Sub Menu Page
const createSubMenuItem = async (body) => {
  const url = "api/submenus_store";
  let data = body;
  return post(url, data);
};

const updateSubMenu = async (body, id) => {
  const url = `api/menus_update/${id}`;
  let data = body;
  return put(url, data);
};
const getSubMenu = async () => {
  const url = "api/menus";
  return get(url);
};

// Slider Page
const createSlider = async (body) => {
  const url = "api/slider_store";
  let data = body;
  return post(url, data);
};

const updateSlider = async (body, id) => {
  const url = `api/slider_update/${id}`;
  let data = body;
  return put(url, data);
};
const getSlider = async () => {
  const url = "api/slider";
  return get(url);
};

// Slider Type Page
const createSliderType = async (body) => {
  const url = "api/slidertypes_store";
  let data = body;
  return post(url, data);
};

const updateSliderType = async (body, id) => {
  const url = `api/slidertypes_update/${id}`;
  let data = body;
  return put(url, data);
};
const getSliderType = async () => {
  const url = "api/slidertypes";
  return get(url);
};


// Website Page

const getPage = async (id) => {
  const url = `api/pages/${id}`;
  return get(url);
};

const getPages = async () => {
  const url = `api/pages`;
  return get(url);
};

// Update Pages

const updateHome = async (body, id) => {
  const url = `api/pageheads_update/${id}`;
  let data = body;
  return put(url, data);
};



export {
  createProduct,
  updateProduct,
  getProduct,
  getCategory,
  getForm,
  getMenu,
  createMenu,
  updateMenu,
  createSubMenuItem,
  updateSubMenu,
  getSubMenu,
  createSlider,
  updateSlider,
  getSlider,
  createSliderType,
  updateSliderType,
  getSliderType,
  getPage,
  getPages,
  updateHome
};
