import React from "react";

const Logout = () => {
  return (
    <React.Fragment>
        Logout
    </React.Fragment>
  );
};

export default Logout;
