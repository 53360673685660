import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  FormFeedback,
} from "reactstrap";
//authentication
import authservice from "../../services/auth.service";
import Logo from "../../assets/logo/Union-Logo-light-menu.png";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import Loading from "../../components/Loading";

const Login = () => {
  document.title = "Login - Union Fabrics";
  const [isLoading, setisLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [IsPasswordVisible, setIsPasswordVisible] = useState(false);
  const [validateErr, setValidateErr] = useState()
  const navigate = useNavigate();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "temp@unionfabrics.com",
      password: "123",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async (values) => {
      setisLoading(true);


      // Remove these Line
      localStorage.setItem("User", "Temp User")
      navigate("/dashboard");
      
      const response = await authservice.login(values);
      console.log(response);
      if (response.IsSuccess === true && response.Status_Code === 200) {
        // const now = new Date();
        // userService.Set_User(response.Data, now.getTime() + 7200000);
        setIsLoggedIn(true);
        setisLoading(false);
      } else if (response.IsSuccess === false && response.Status_Code === 401) {
        setisLoading(false);
        setValidateErr("Invalid Credentials")
      } else {
        console.log(response.message);
        setisLoading(false);
        setValidateErr("Invalid Credentials")
      }
    },
  });

  if (isLoggedIn) {
    // window.location.href = "/dashboard";
    navigate("/dashboard");
  }

  const showPassword = () => {
    setIsPasswordVisible(!IsPasswordVisible);
  };

  return (
    <React.Fragment>
      <div className="limiter">
        <div className="container-login">
          <div className="wrap-login">
            <Form
              className="login-form"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <img src={Logo} alt="Site Logo" className="login-form-logo"></img>
              <span className="login-form-title">Welcome</span>

              <div className="wrap-input" data-validate="Valid email is: a@b.c">
                {/* <Label className="input-label">Email</Label> */}
                <Input
                  name="email"
                  className="form-input"
                  placeholder="Enter Email"
                  type="email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
              </div>
              {validation.touched.email && validation.errors.email ? (
                <FormFeedback className="err" type="invalid">
                  {validation.errors.email}
                </FormFeedback>
              ) : null}

              <div className="wrap-input" data-validate="Enter password">
                {/* <Label className="input-label">Password</Label> */}
                <span className="btn-show-pass">
                  <button style={{}} onClick={showPassword} type="button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={showPassword}
                      width="1.23em"
                      height="1em"
                      viewBox="0 0 472 384"
                    >
                      <path
                        fill="currentColor"
                        d="M235 32q79 0 142.5 44.5T469 192q-28 71-91.5 115.5T235 352T92 307.5T0 192q28-71 92-115.5T235 32m0 267q44 0 75-31.5t31-75.5t-31-75.5T235 85t-75.5 31.5T128 192t31.5 75.5T235 299m-.5-171q26.5 0 45.5 18.5t19 45.5t-19 45.5t-45.5 18.5t-45-18.5T171 192t18.5-45.5t45-18.5"
                      />
                    </svg>
                  </button>
                </span>
                <Input
                  className="form-input"
                  name="password"
                  value={validation.values.password || ""}
                  type={IsPasswordVisible ? "text" : "password"}
                  placeholder="Enter Password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.password && validation.errors.password
                      ? true
                      : false
                  }
                />
              </div>
              {validation.touched.password && validation.errors.password ? (
                <FormFeedback  className="err" type="invalid">
                  {validation.errors.password}
                </FormFeedback>
              ) : null}

              <div className="container-login-form-btn">
                <div className="wrap-login-form-btn">
                  <div className="login-form-bgbtn">
                    <button className="login-form-btn" type="submit">
                      Login
                    </button>
                  </div>
                </div>
              </div>
              {validateErr ? (
                <FormFeedback  className="err" type="invalid">
                  {validateErr}
                </FormFeedback>
              ) : null}
            </Form>
          </div>
        </div>

        {isLoading ? (
          <div
            style={{
              position: "fixed",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              justifyContent: "center",
              backgroundColor: "#333",
              opacity: 0.8,
            }}
            pointerEvents={"none"}
          >
            <Loading />
          </div>
        ) : (
          ""
        )}


      </div>
    </React.Fragment>
  );
};

export default Login;
