import React, { useState, useEffect } from "react";
import { getPage, updateHome } from "../../../services/dashboard.service";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { MdUpload } from "react-icons/md";

function HomePage() {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState();

  const handleInputChange = (e) => {
    const { name, value, id } = e.target;
    const inputValue = value;
    setFormData({
      ...formData,
      [name]: inputValue,
    });

    switch (id) {
      case "About":
        const aboutSectionIndex = formData.Sections.findIndex(
          (section) => section.PH_Id === 1
        );

        formData.Sections[aboutSectionIndex][name] = inputValue;
        break;

      case "Timeline":
        const timelineSectionIndex = formData.Sections.findIndex(
          (section) => section.PH_Id === 2
        );

        formData.Sections[timelineSectionIndex][name] = inputValue;
        break;

      case "OurProduct":
        const productSectionIndex = formData.Sections.findIndex(
          (section) => section.PH_Id === 5
        );

        formData.Sections[productSectionIndex][name] = inputValue;
        break;

      default:
        console.error("Invalid section ID");
    }
  };

  const timelineChange = (e) => {
    const { name, value, id } = e.target;

    var currentdate = new Date();

    currentdate =
      currentdate.getFullYear() +
      "-" +
      (currentdate.getMonth() + 1) +
      "-" +
      currentdate.getDate() +
      " " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds();

    const timelineSectionIndex = formData.Sections.findIndex(
      (section) => section.PH_Id === 2
    );

    const detailIndex = formData.Sections[
      timelineSectionIndex
    ].More_Detail.findIndex((detail) => detail.PHD_Id === parseInt(id));

    if (timelineSectionIndex !== -1 && detailIndex !== -1) {
      const updatedFormData = { ...formData };
      updatedFormData.Sections[timelineSectionIndex].More_Detail[
        detailIndex
      ].updated_at = currentdate;
      updatedFormData.Sections[timelineSectionIndex].More_Detail[detailIndex][
        name
      ] = value;
      setFormData(updatedFormData);
    } else {
      console.error(
        "Timeline section or More_Detail object not found in form data"
      );
    }
  };

  const productChange = (e) => {
    const { name, value, id } = e.target;

    const Index = formData.Sections.findIndex((section) => section.PH_Id === 5);

    const detailIndex = formData.Sections[Index].More_Detail.findIndex(
      (detail) => detail.PHD_Id === parseInt(id)
    );

    if (Index !== -1 && detailIndex !== -1) {
      const updatedFormData = { ...formData };
      updatedFormData.Sections[Index].More_Detail[detailIndex][name] = value;
      setFormData(updatedFormData);
    } else {
      console.error(
        "Timeline section or More_Detail object not found in form data"
      );
    }
  };
  const handleImageChange = (event, id) => {
    const file = event.target.files[0];

    // Display image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      const Index = formData.Sections.findIndex(
        (section) => section.PH_Id === 5
      );

      const detailIndex = formData.Sections[Index].More_Detail.findIndex(
        (detail) => detail.PHD_Id === parseInt(id)
      );
      const updatedFormData = { ...formData };
      updatedFormData.Sections[Index].More_Detail[detailIndex].Image =
        reader.result;
      setFormData(updatedFormData);
    };
    reader.readAsDataURL(file);
  };

  const updatePage = async () => {
    try {
      await updateHome(formData, 1);
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPage(1);
        // if (!response.ok) {
        //   throw new Error("Network response was not ok");
        // }
        const jsonData = await response;
        setData(jsonData);
        setFormData({
          Page_Title: jsonData[0].Page_Title,
          Page_Link: jsonData[0].Page_Link,
          Meta_Name: jsonData[0].Meta_Name,
          Meta_Description: jsonData[0].Meta_Description,
          Sections: [
            {
              PH_Id: 1,
              Head_Title: jsonData[0].Sections.find((data) => data.PH_Id === 1)
                .Head_Title,
              Content_Title: jsonData[0].Sections.find(
                (data) => data.PH_Id === 1
              ).Content_Title,
              Content: jsonData[0].Sections.find((data) => data.PH_Id === 1)
                .Content,
              Image: null,
              More_Detail: [],
            },
            {
              PH_Id: 2,
              Head_Title: jsonData[0].Sections.find((data) => data.PH_Id === 2)
                .Head_Title,
              Content_Title: jsonData[0].Sections.find(
                (data) => data.PH_Id === 2
              ).Content_Title,
              Content: jsonData[0].Sections.find((data) => data.PH_Id === 2)
                .Content,
              Image: null,
              More_Detail: [
                {
                  PHD_Id: 3,
                  PH_Id: 2,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 2
                  ).More_Detail.find((data) => data.PHD_Id === 3).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 2
                  ).More_Detail.find((data) => data.PHD_Id === 3).Content,
                  Image: null,
                  Link: null,
                  created_at: null,
                  updated_at: null,
                },
                {
                  PHD_Id: 4,
                  PH_Id: 2,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 2
                  ).More_Detail.find((data) => data.PHD_Id === 4).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 2
                  ).More_Detail.find((data) => data.PHD_Id === 4).Content,
                  Image: null,
                  Link: null,
                  created_at: null,
                  updated_at: null,
                },
                {
                  PHD_Id: 7,
                  PH_Id: 2,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 2
                  ).More_Detail.find((data) => data.PHD_Id === 7).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 2
                  ).More_Detail.find((data) => data.PHD_Id === 7).Content,
                  Image: null,
                  Link: null,
                  created_at: null,
                  updated_at: null,
                },

                {
                  PHD_Id: 8,
                  PH_Id: 2,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 2
                  ).More_Detail.find((data) => data.PHD_Id === 8).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 2
                  ).More_Detail.find((data) => data.PHD_Id === 8).Content,
                  Image: null,
                  Link: null,
                  created_at: null,
                  updated_at: null,
                },

                {
                  PHD_Id: 9,
                  PH_Id: 2,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 2
                  ).More_Detail.find((data) => data.PHD_Id === 9).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 2
                  ).More_Detail.find((data) => data.PHD_Id === 9).Content,
                  Image: null,
                  Link: null,
                  created_at: null,
                  updated_at: null,
                },
                {
                  PHD_Id: 10,
                  PH_Id: 2,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 2
                  ).More_Detail.find((data) => data.PHD_Id === 10).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 2
                  ).More_Detail.find((data) => data.PHD_Id === 10).Content,
                  Image: null,
                  Link: null,
                  created_at: null,
                  updated_at: null,
                },
              ],
            },
            {
              PH_Id: 5,
              Head_Title: jsonData[0].Sections.find((data) => data.PH_Id === 5)
                .Head_Title,
              Content_Title: jsonData[0].Sections.find(
                (data) => data.PH_Id === 5
              ).Content_Title,
              Content: jsonData[0].Sections.find((data) => data.PH_Id === 5)
                .Content,
              Image: null,
              More_Detail: [
                {
                  PHD_Id: 20,
                  PH_Id: 5,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 5
                  ).More_Detail.find((data) => data.PHD_Id === 20).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 5
                  ).More_Detail.find((data) => data.PHD_Id === 20).Content,
                  Image: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 5
                  ).More_Detail.find((data) => data.PHD_Id === 20).Image,
                  Link: "#",
                  created_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 5
                  ).More_Detail.find((data) => data.PHD_Id === 20).created_at,
                  updated_at: "2024-02-13 12:24:16",
                },
                {
                  PHD_Id: 21,
                  PH_Id: 5,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 5
                  ).More_Detail.find((data) => data.PHD_Id === 21).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 5
                  ).More_Detail.find((data) => data.PHD_Id === 21).Content,
                  Image: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 5
                  ).More_Detail.find((data) => data.PHD_Id === 21).Image,
                  Link: "#",
                  created_at: "2024-02-13 12:24:16",
                  updated_at: "2024-02-13 12:24:16",
                },
                {
                  PHD_Id: 22,
                  PH_Id: 5,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 5
                  ).More_Detail.find((data) => data.PHD_Id === 22).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 5
                  ).More_Detail.find((data) => data.PHD_Id === 22).Content,
                  Image: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 5
                  ).More_Detail.find((data) => data.PHD_Id === 22).Image,
                  Link: "#",
                  created_at: "2024-02-13 12:24:16",
                  updated_at: "2024-02-13 12:24:16",
                },
              ],
            },
          ],
        });
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div
      className="dashboard-component-container"
      // style={{ border: "1px solid #000" }}
    >
      <div className="pageTitle">
        <h2
          style={{
            width: "100%",
            backgroundColor: "var(--color-primary)",
            padding: "10px",
            color: "white",
            fontSize: "26px",
          }}
        >
          <span className="pageTitle-highlight">
            {data[0].Page_Title + " Page"}
          </span>
        </h2>
        <form className="form">
          <div
            className="formGroup"
            style={{ gap: "10px", marginBottom: "20px" }}
          >
            <TextField
              id="Page_Title"
              label="Page Title"
              name="Page_Title"
              value={formData.Page_Title}
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="Meta_Name"
              label="Meta Name"
              name="Meta_Name"
              value={formData.Meta_Name}
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="Page_Link"
              label="Page Link"
              name="Page_Link"
              value={formData.Page_Link}
              onChange={handleInputChange}
              disabled
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="Meta_Description"
              label="Meta Description"
              name="Meta_Description"
              value={formData.Meta_Description}
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
          </div>

          {/* ABOUT SECTION */}

          <div
            className="formGroup"
            style={{ gap: "10px", marginBottom: "20px" }}
          >
            <h2
              style={{
                fontSize: "16px",
                width: "100%",
                backgroundColor: "var(--color-primary)",
                padding: "10px",
                color: "white",
              }}
            >
              <span className="pageTitle-highlight">SECTION - ABOUT</span>
            </h2>
            <TextField
              id="About"
              label="Head Title"
              name="Head_Title"
              value={
                formData.Sections.find((data) => data.PH_Id === 1).Head_Title
              }
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="About"
              label="Content Title"
              name="Content_Title"
              value={
                formData.Sections.find((data) => data.PH_Id === 1).Content_Title
              }
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="About"
              label="Content"
              multiline
              rows={4}
              variant="outlined"
              name="Content"
              value={formData.Sections.find((data) => data.PH_Id === 1).Content}
              onChange={handleInputChange}
              style={{ marginTop: "5px", width: "100%" }}
            />
          </div>

          {/* TIMELINE SECTION */}

          <div
            className="formGroup"
            style={{ gap: "10px", marginBottom: "20px" }}
          >
            <h2
              style={{
                fontSize: "16px",
                width: "100%",
                backgroundColor: "var(--color-primary)",
                padding: "10px",
                color: "white",
              }}
            >
              <span className="pageTitle-highlight">SECTION - TIME LINE </span>
            </h2>
            <TextField
              id="Timeline"
              label="Head Title"
              name="Head_Title"
              value={
                formData.Sections.find((data) => data.PH_Id === 2).Head_Title
              }
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="Timeline"
              label="Content Title"
              name="Content_Title"
              value={
                formData.Sections.find((data) => data.PH_Id === 2).Content_Title
              }
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="Timeline"
              label="Content"
              multiline
              rows={4}
              variant="outlined"
              name="Content"
              value={formData.Sections.find((data) => data.PH_Id === 2).Content}
              onChange={handleInputChange}
              style={{ marginTop: "5px", width: "100%" }}
            />

            <div className="sectionItems">
              {data[0].Sections[1].More_Detail.map((row, index) => (
                <div key={row.PHD_Id} className="sectionItem">
                  <h2
                    style={{
                      fontSize: "14px",
                      width: "100%",
                      backgroundColor: "#44596E",
                      padding: "10px",
                      color: "white",
                    }}
                  >
                    <span className="pageTitle-highlight">
                      TIME LINE - {index + 1}
                    </span>
                  </h2>
                  <TextField
                    id={row.PHD_Id.toString()}
                    label="Title"
                    name="Title"
                    value={
                      formData.Sections.find(
                        (data) => data.PH_Id === 2
                      ).More_Detail.find((data) => data.PHD_Id === row.PHD_Id)
                        .Title
                    }
                    onChange={timelineChange}
                    variant="outlined"
                    style={{
                      marginTop: "5px",
                      width: "100%",
                    }}
                  />
                  <TextField
                    id={row.PHD_Id.toString()}
                    label="Content"
                    multiline
                    rows={4}
                    variant="outlined"
                    name="Content"
                    value={
                      formData.Sections.find(
                        (data) => data.PH_Id === 2
                      ).More_Detail.find((data) => data.PHD_Id === row.PHD_Id)
                        .Content
                    }
                    onChange={timelineChange}
                    style={{ marginTop: "5px", width: "100%" }}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* OUR PRODUCTS SECTION */}

          <div
            className="formGroup"
            style={{
              gap: "10px",
              marginBottom: "20px",
            }}
          >
            <h2
              style={{
                fontSize: "16px",
                width: "100%",
                backgroundColor: "var(--color-primary)",
                padding: "10px",
                color: "white",
              }}
            >
              <span className="pageTitle-highlight">
                SECTION - OUR PRODUCTS
              </span>
            </h2>
            <TextField
              id="OurProduct"
              label="Head Title"
              name="Head_Title"
              value={
                formData.Sections.find((data) => data.PH_Id === 5).Head_Title
              }
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="OurProduct"
              label="Content Title"
              name="Content_Title"
              value={
                formData.Sections.find((data) => data.PH_Id === 5).Content_Title
              }
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="OurProduct"
              label="Content"
              multiline
              rows={4}
              variant="outlined"
              name="Content"
              value={formData.Sections.find((data) => data.PH_Id === 5).Content}
              onChange={handleInputChange}
              style={{ marginTop: "5px", width: "100%" }}
            />

            <div className="sectionItems">
              {data[0].Sections[2].More_Detail.map((row, index) => (
                <div key={row.PHD_Id} className="sectionItem">
                  <h2
                    style={{
                      fontSize: "14px",
                      width: "100%",
                      backgroundColor: "#44596E",
                      padding: "10px",
                      color: "white",
                      marginTop: "5px",
                    }}
                  >
                    <span className="pageTitle-highlight">
                      PRODUCT - {index + 1}
                    </span>
                  </h2>
                  <TextField
                    id={row.PHD_Id.toString()}
                    label="Title"
                    name="Title"
                    value={
                      formData.Sections.find(
                        (data) => data.PH_Id === 5
                      ).More_Detail.find((data) => data.PHD_Id === row.PHD_Id)
                        .Title
                    }
                    onChange={productChange}
                    variant="outlined"
                    style={{
                      marginTop: "5px",
                      width: "100%",
                    }}
                  />
                  <img
                    id={row.PHD_Id.toString()}
                    onChange={handleImageChange}
                    src={
                      formData.Sections.find(
                        (data) => data.PH_Id === 5
                      ).More_Detail.find((data) => data.PHD_Id === row.PHD_Id)
                        .Image
                    }
                    alt={row.PHD_Id}
                    style={{
                      width: "100%",
                      marginTop: "5px",
                      objectFit: "contain",
                      height: "300px",
                    }}
                  />

                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    // onChange={handleImageChange}
                    style={{ marginTop: "5px", backgroundColor: "#44596E" }}
                    startIcon={<MdUpload />}
                  >
                    Upload Picture
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(event) =>
                        handleImageChange(event, row.PHD_Id.toString())
                      }
                    />
                  </Button>
                </div>
              ))}
            </div>
          </div>

          <div className="addButton">
            <Button
              style={{
                border: "1px solid var(--color-primary)",
                color: "var(--color-primary)",
              }}
              variant="outlined"
              // startIcon={<FaCirclePlus />}
              onClick={() => updatePage()}
            >
              Save Data
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default HomePage;
