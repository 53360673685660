import React, { useState, useEffect } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
} from "react-pro-sidebar";
import { Link, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Logo from "../../assets/logo/Union-Logo-light-menu.png";
import mobileLogo from "../../assets/logo/logo.png";

// Icons
import { FaChevronCircleRight } from "react-icons/fa";
import { FaChevronCircleLeft } from "react-icons/fa";
import { FaList } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { RiPagesFill } from "react-icons/ri";
import { TfiMenuAlt } from "react-icons/tfi";
import { ImMenu } from "react-icons/im";
import { BiMenuAltRight } from "react-icons/bi";
import { TfiLayoutSliderAlt } from "react-icons/tfi";
import { FiSliders } from "react-icons/fi";
import { TfiLayoutSlider } from "react-icons/tfi";
import { SiGoogleforms } from "react-icons/si";
import { FaGlobeAmericas } from "react-icons/fa";
import { MdCategory } from "react-icons/md";
import { FaBoxesPacking } from "react-icons/fa6";
import { RiAdminFill } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import { MdLogout } from "react-icons/md";

const themes = {
  light: {
    sidebar: {
      backgroundColor: "var(--color-primary)",
      color: "#fff",
    },
    menu: {
      menuContent: "var(--color-primary)",
      subMenuContent: "#b45808",
      icon: "#fff",
      iconSize: "20px",
      hover: {
        backgroundColor: "black",
        color: "#fff",
      },
      disabled: {
        color: "#9fb6cf",
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: "var(--color-primary)",
      color: "#fff ",
    },
    menu: {
      menuContent: "var(--color-primary)",
      icon: "#fff !important",
      iconSize: "24px",
      hover: {
        backgroundColor: "#black",
        color: "#fff",
      },
      disabled: {
        color: "#fff",
      },
    },
  },
};

const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = React.useState(false);
  const [broken, setBroken] = React.useState(
    window.matchMedia("(max-width: 800px)").matches
  );

  const [pageTitle, setPageTitle] = useState("Dashboard");
  const location = useLocation();
 
  useEffect(() => {
    // Function to get the current path name from the URL
    const getCurrentPathName = () => {
      const pathName = window.location.pathname;
      if(pathName === "/") navigate("/dashboard");
      return pathName.substring(1); // Exclude the leading slash
    };

    // Update the page title based on the current path name
    const updatePageTitle = () => {
      const pathName = getCurrentPathName();
      // Logic to set the page title based on the path name
      switch (pathName) {
        case "dashboard":
          setPageTitle("Home");
          break;
        case "dashboard/pages":
          setPageTitle("Website Pages");
          break;
        case "dashboard/menu":
          setPageTitle("Menus");
          break;
        case "dashboard/sub-menu":
          setPageTitle("Sub Menus");
          break;
        case "dashboard/slider-types":
          setPageTitle("Slider Types");
          break;
        case "dashboard/slider":
          setPageTitle("Slider");
          break;
        case "dashboard/form":
          setPageTitle("Website Form");
          break;
        case "dashboard/category":
          setPageTitle("Category");
          break;
        case "dashboard/product":
          setPageTitle("Product");
          break;
        case "dashboard/profile":
          setPageTitle("Profile");
          break;
        default:
          setPageTitle("Dashboard");
          break;
      }
    };
    // Call the updatePageTitle function once when the component mounts
    updatePageTitle();

    // Add event listener to update the page title whenever the URL changes
    window.addEventListener("popstate", updatePageTitle);

    // Cleanup: remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", updatePageTitle);
    };
  }, [location]);

  const [theme, setTheme] = useState("light");

  const menuItemStyles = {
    root: {
      fontSize: "14px",
      fontWeight: 500,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      fontSize: themes[theme].menu.iconSize,
    },
    SubMenuExpandIcon: {
      color: themes[theme].menu.icon,
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0 ? themes[theme].menu.subMenuContent : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: themes[theme].menu.hover.backgroundColor,
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  // Logout Button
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("User", "Temp User");
    navigate("/login");
  };

  return (
    <React.Fragment>
      <div className="Dashboard-Container">
        <div className="Main-Sidebar">
          <Sidebar
            collapsed={collapsed}
            toggled={toggled}
            onBackdropClick={() => setToggled(false)}
            onBreakPoint={setBroken}
            // image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
            breakPoint="md"
            backgroundColor={themes[theme].sidebar.backgroundColor}
            transitionDuration={500}
            rootStyles={{
              color: themes[theme].sidebar.color,
            }}
            className="SidebarPro"
          >
            <div className="Sidebar-Content">
              <div className="Sidebar-Content-Wrapper">
                <div className="sidebar-header">
                  {collapsed ? (
                    <img
                      src={mobileLogo}
                      alt="Site Logo"
                      className="mobileLogo"
                    ></img>
                  ) : (
                    <img src={Logo} alt="Site Logo" className="logo"></img>
                  )}
                </div>
                <Menu menuItemStyles={menuItemStyles}>
                  <MenuItem icon={<MdDashboard />} component={<Link to="" />}>
                    Dashboard
                  </MenuItem>
                  <MenuItem
                    icon={<RiPagesFill />}
                    component={<Link to="pages" />}
                  >
                    Website Pages
                  </MenuItem>
                  <SubMenu label="Website Menu" icon={<TfiMenuAlt />}>
                    <MenuItem icon={<ImMenu />} component={<Link to="menu" />}>
                      Menu
                    </MenuItem>
                    <MenuItem
                      icon={<BiMenuAltRight />}
                      component={<Link to="sub-menu" />}
                    >
                      Sub Menu
                    </MenuItem>
                  </SubMenu>
                  <SubMenu label="Sliders" icon={<TfiLayoutSliderAlt />}>
                    <MenuItem
                      icon={<FiSliders />}
                      component={<Link to="slider-types" />}
                    >
                      Slider Types
                    </MenuItem>
                    <MenuItem
                      icon={<TfiLayoutSlider />}
                      component={<Link to="slider" />}
                    >
                      Sliders
                    </MenuItem>
                  </SubMenu>
                  <MenuItem
                    icon={<SiGoogleforms />}
                    component={<Link to="form" />}
                  >
                    Website Form
                  </MenuItem>
                  <SubMenu label="AR Portal" icon={<FaGlobeAmericas />}>
                    <MenuItem
                      icon={<MdCategory />}
                      component={<Link to="category" />}
                    >
                      Category
                    </MenuItem>
                    <MenuItem
                      icon={<FaBoxesPacking />}
                      component={<Link to="product" />}
                    >
                      Products
                    </MenuItem>
                  </SubMenu>
                  <SubMenu label="Admin User" icon={<RiAdminFill />}>
                    <MenuItem
                      icon={<FaUser />}
                      component={<Link to="profile" />}
                    >
                      Profile
                    </MenuItem>
                  </SubMenu>
                </Menu>

                <div className="separator"></div>

                <Menu menuItemStyles={menuItemStyles}>
                  <MenuItem onClick={handleLogout} icon={<MdLogout />}>Logout</MenuItem>
                </Menu>
              </div>
            </div>
          </Sidebar>

          <main>
            <div className="Sidebar-Buttons">
              <div className="Sidebar-Buttons-Wrapper">
                <button
                  className="Collapse-Button"
                  onClick={() => setCollapsed(!collapsed)}
                >
                  {!broken ? (
                    collapsed ? (
                      <FaChevronCircleRight />
                    ) : (
                      <FaChevronCircleLeft />
                    )
                  ) : (
                    <div></div>
                  )}
                </button>
              </div>
            </div>
          </main>
        </div>
        <div className="Main-Content">
          <div className="Content-Header">
            <h1>{pageTitle}</h1>
            {broken && (
              <button
                className="Toggle-Button"
                onClick={() => setToggled(!toggled)}
              >
                <FaList></FaList>
              </button>
            )}
          </div>
          <div className="Content-Body">
            <Outlet></Outlet>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
