import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// Icons
// import { FaCirclePlus } from "react-icons/fa6";
import { FaPen } from "react-icons/fa";
// import { MdSend } from "react-icons/md";
// import { MdUpload } from "react-icons/md";

// Form Feilds
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";

import {
  // createMenu,
  updateMenu,
  getMenu,
} from "../../../services/dashboard.service";

function Menu() {
  const [menuData, setMenuData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMenuId, setEditMenuId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    Menu_Name: "",
    Link: "",
    IsActive: false,
    IsDelete: false,
    // CreatedBy: 1,
    // CreatorFirstName: "",
  });

  // const creatMenu = () => {
  //   setCreate(true);
  //   editData();
  // };

  const editData = (menuId) => {
    const selectedMenu = menuData.find((menu) => menu.Menu_Id === menuId);
    if (selectedMenu) {
      setFormData({
        Menu_Name: selectedMenu.Menu_Name,
        Link: selectedMenu.Link,
        IsActive: selectedMenu.IsActive,
        IsDelete: selectedMenu.IsDelete,
        // CreatedBy: selectedMenu.CreatedBy,
        // CreatorFirstName: selectedMenu.CreatorFirstName,
      });
    }
    setEditMenuId(menuId);
    setOpenDialog(true);
  };

  const closeEditForm = () => {
    setFormData({
      Menu_Name: "",
      Link: "",
      IsActive: true,
      IsDelete: false,
    });
    // setCreate(false);
    setMessageError(false);
    setEditMenuId(null);
    setOpenDialog(false);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: inputValue,
    });
  };

  // const [create, setCreate] = useState(false);
  const [messageError, setMessageError] = useState(false);

  // const formDataWithCreatedBy = {
  //   ...formData,
  //   IsEditable: true,
  //   CreatedBy: "1", //Get ID from Local Storage
  // };

  const formDataWithModifyBy = {
    ...formData,
    IsEditable: true,
    ModifyBy: "1", //Get ID from Local Storage
  };

  const handleSubmit = async () => {
    if (formData.Menu_Name === "") {
      setMessageError(true);
      // console.log(formData);
    // } else {
      // if (create) {
        // try {
        //   console.log(JSON.stringify(formDataWithCreatedBy));
        //   const response = await createMenu(
        //     JSON.stringify(formDataWithCreatedBy)
        //   );
        //   console.log(response);
        //   closeEditForm();
        // } catch (error) {
        //   console.error("Error adding menu:", error);
        // }
      } else {
        try {
          const response = await updateMenu(formDataWithModifyBy, editMenuId);
          // if (!response.ok) {
          //   throw new Error("Network response was not ok");
          // }
          // Handle success response
          console.log(response);
          // Optionally, you can fetch updated data from the server and update state
          closeEditForm();
        } catch (error) {
          console.error("Error updating menu:", error);
        }

        setFormData({
          Menu_Name: "",
          Link: "",
          IsActive: true,
          IsDelete: false,
        });
        setOpenDialog(false);
        // setCreate(false);
        setMessageError(false);
      }
    // }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getMenu();
        // if (!response.ok) {
        //   throw new Error("Network response was not ok");
        // }
        const jsonData = await response;
        console.log(jsonData)
        setMenuData(jsonData);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="dashboard-component-container">
      {/* <div className="addButton">
        <Button
          style={{
            border: "1px solid var(--color-primary)",
            color: "var(--color-primary)",
          }}
          variant="outlined"
          startIcon={<FaCirclePlus />}
          onClick={() => creatMenu()}
        >
          Add Menu
        </Button>
      </div> */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">Menu Name</TableCell>
              <TableCell align="center">Link</TableCell>
              <TableCell align="center">Active</TableCell>
              {/* <TableCell align="center">Delete</TableCell> */}
              {/* <TableCell align="center">Created By</TableCell> */}
              <TableCell align="center">Creator First Name</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {menuData.length < 0 ?  menuData  :
            menuData.map((row) => (
              <TableRow
                key={row.Menu_Id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center" component="th" scope="row">
                  {row.Menu_Id}
                </TableCell>
                <TableCell align="left">{row.Menu_Name}</TableCell>
                <TableCell align="center">{row.Link}</TableCell>
                <TableCell align="center">{row.IsActive.toString()}</TableCell>
                {/* <TableCell align="center">{row.IsDelete.toString()}</TableCell> */}
                {/* <TableCell align="center">{row.CreatedBy}</TableCell> */}
                <TableCell align="center">
                  {row.CreatorFirstName || "Null"}
                </TableCell>
                <TableCell align="center">
                  <button
                    className="Edit-Button"
                    onClick={() => editData(row.Menu_Id)}
                  >
                    <FaPen></FaPen>
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={openDialog} onClose={closeEditForm} className="Dialog">
        <DialogTitle>
        Edit Menu ID: ${editMenuId}
        </DialogTitle>
        <DialogContent className="DialogContent">
          <form className="form">
            <div className="formGroup">
              {/* <label htmlFor="menuName">Menu Name:</label>
              <input
                type="text"
                id="menuName"
                name="Menu_Name"
                value={formData.Menu_Name}
                onChange={handleInputChange}
              /> */}
              <TextField
                id="menuName"
                label="Menu Name"
                name="Menu_Name"
                value={formData.Menu_Name}
                onChange={handleInputChange}
                variant="outlined"
                style={{ marginTop: "5px", width: "100%" }}
              />
              <TextField
                id="link"
                label="Link"
                name="Link"
                value={formData.Link}
                onChange={handleInputChange}
                variant="outlined"
                style={{ marginTop: "5px", width: "100%" }}
              />
              <FormControlLabel
                label="Active"
                style={{ marginTop: "5px" }}
                control={
                  <Checkbox
                    inputProps={{ "aria-label": "controlled" }}
                    id="isActive"
                    name="IsActive"
                    checked={formData.IsActive}
                    onChange={handleInputChange}
                  />
                }
              />
              {/* <TextField
                id="CreatedBy"
                label="Created By"
                name="CreatedBy"
                value={formData.CreatedBy}
                onChange={handleInputChange}
                variant="outlined"
                style={{ marginTop: "5px", width: "100%" }}
                disabled
              />
              <TextField
                id="CreatorFirstName"
                label="Creator First Name"
                name="CreatorFirstName"
                value={formData.CreatorFirstName}
                onChange={handleInputChange}
                variant="outlined"
                style={{ marginTop: "5px", width: "100%" }}
                disabled
              /> */}

              {messageError ? (
                <p className="errorMessage">
                  Please enter all the required fields
                </p>
              ) : (
                <p></p>
              )}
            </div>
            {/* <div className="formGroup">
              <label htmlFor="link">Link:</label>
              <input
                type="text"
                id="link"
                name="Link"
                value={formData.Link}
                onChange={handleInputChange}
              />
            </div>
            <div className="formGroup">
              <label htmlFor="isActive">Active:</label>
              <input
                type="checkbox"
                id="isActive"
                name="IsActive"
                checked={formData.IsActive}
                onChange={handleInputChange}
              />
            </div>
            <div className="formGroup">
              <label htmlFor="IsDelete">Delete:</label>
              <input
                type="checkbox"
                id="IsDelete"
                name="IsDelete"
                checked={formData.IsDelete}
                onChange={handleInputChange}
              />
            </div>
            <div className="formGroup">
              <label htmlFor="CreatedBy">Created By:</label>
              <input
                type="text"
                id="CreatedBy"
                name="CreatedBy"
                value={formData.CreatedBy}
                onChange={handleInputChange}
              />
            </div>
            <div className="formGroup">
              <label htmlFor="CreatorFirstName">Creator First Name:</label>
              <input
                type="text"
                id="CreatorFirstName"
                name="CreatorFirstName"
                value={formData.CreatorFirstName}
                onChange={handleInputChange}
              />
            </div> */}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditForm}>Cancel</Button>
          {/* <Button onClick={handleSubmit}> {create ? "Create" : "Save"}</Button> */}
          <Button onClick={handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Menu;
