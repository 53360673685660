import React, { useState, useEffect } from "react";
import { getPage, updateHome } from "../../../services/dashboard.service";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { MdUpload } from "react-icons/md";

function HomeTextiles() {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState();

  const handleInputChange = (e) => {
    const { name, value, id } = e.target;
    const inputValue = value;
    setFormData({
      ...formData,
      [name]: inputValue,
    });

    switch (id) {
      case "Choose":
        const ChooseIndex = formData.Sections.findIndex(
          (section) => section.PH_Id === 12
        );

        formData.Sections[ChooseIndex][name] = inputValue;
        break;
      case "Brief":
        const BriefIndex = formData.Sections.findIndex(
          (section) => section.PH_Id === 13
        );

        formData.Sections[BriefIndex][name] = inputValue;
        break;
      case "State":
        const StateIndex = formData.Sections.findIndex(
          (section) => section.PH_Id === 14
        );

        formData.Sections[StateIndex][name] = inputValue;
        break;
      case "Crafting":
        const CraftingIndex = formData.Sections.findIndex(
          (section) => section.PH_Id === 15
        );

        formData.Sections[CraftingIndex][name] = inputValue;
        break;

      default:
        console.error("Invalid section ID");
    }
  };

  const briefChange = (e) => {
    const { name, value, id } = e.target;

    var currentdate = new Date();

    currentdate =
      currentdate.getFullYear() +
      "-" +
      (currentdate.getMonth() + 1) +
      "-" +
      currentdate.getDate() +
      " " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds();

    const Index = formData.Sections.findIndex(
      (section) => section.PH_Id === 13
    );

    const detailIndex = formData.Sections[Index].More_Detail.findIndex(
      (detail) => detail.PHD_Id === parseInt(id)
    );

    if (Index !== -1 && detailIndex !== -1) {
      const updatedFormData = { ...formData };
      updatedFormData.Sections[Index].More_Detail[detailIndex][name] = value;
      updatedFormData.Sections[Index].More_Detail[detailIndex].updated_at =
        currentdate;
      setFormData(updatedFormData);
    } else {
      console.error(
        "Brief section or More_Detail object not found in form data"
      );
    }
  };

  const stateChange = (e) => {
    const { name, value, id } = e.target;

    var currentdate = new Date();

    currentdate =
      currentdate.getFullYear() +
      "-" +
      (currentdate.getMonth() + 1) +
      "-" +
      currentdate.getDate() +
      " " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds();

    const Index = formData.Sections.findIndex(
      (section) => section.PH_Id === 14
    );

    const detailIndex = formData.Sections[Index].More_Detail.findIndex(
      (detail) => detail.PHD_Id === parseInt(id)
    );

    if (Index !== -1 && detailIndex !== -1) {
      const updatedFormData = { ...formData };
      updatedFormData.Sections[Index].More_Detail[detailIndex][name] = value;
      updatedFormData.Sections[Index].More_Detail[detailIndex].updated_at =
        currentdate;
      setFormData(updatedFormData);
    } else {
      console.error(
        "State section or More_Detail object not found in form data"
      );
    }
  };

  const craftingChange = (e) => {
    const { name, value, id } = e.target;

    var currentdate = new Date();

    currentdate =
      currentdate.getFullYear() +
      "-" +
      (currentdate.getMonth() + 1) +
      "-" +
      currentdate.getDate() +
      " " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds();

    const Index = formData.Sections.findIndex(
      (section) => section.PH_Id === 15
    );

    const detailIndex = formData.Sections[Index].More_Detail.findIndex(
      (detail) => detail.PHD_Id === parseInt(id)
    );

    if (Index !== -1 && detailIndex !== -1) {
      const updatedFormData = { ...formData };
      updatedFormData.Sections[Index].More_Detail[detailIndex][name] = value;
      updatedFormData.Sections[Index].More_Detail[detailIndex].updated_at =
        currentdate;
      setFormData(updatedFormData);
    } else {
      console.error(
        "Brief section or More_Detail object not found in form data"
      );
    }
  };

  const handleImageChange = (event, id) => {
    const file = event.target.files[0];

    // Display image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      const Index = formData.Sections.findIndex(
        (section) => section.PH_Id === 13
      );

      const detailIndex = formData.Sections[Index].More_Detail.findIndex(
        (detail) => detail.PHD_Id === parseInt(id)
      );
      const updatedFormData = { ...formData };
      updatedFormData.Sections[Index].More_Detail[detailIndex].Image =
        reader.result;
      setFormData(updatedFormData);
    };
    reader.readAsDataURL(file);
  };

  const handleImageChange2 = (event, id) => {
    const file = event.target.files[0];

    // Display image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      const Index = formData.Sections.findIndex(
        (section) => section.PH_Id === 14
      );

      const detailIndex = formData.Sections[Index].More_Detail.findIndex(
        (detail) => detail.PHD_Id === parseInt(id)
      );
      const updatedFormData = { ...formData };
      updatedFormData.Sections[Index].More_Detail[detailIndex].Image =
        reader.result;
      setFormData(updatedFormData);
    };
    reader.readAsDataURL(file);
  };

  const updatePage = async () => {
    try {
      await updateHome(formData, 7);
    } catch (error) {
      console.error("Error updating page:", error);
    }

    // console.log(formData)
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPage(3);
        // if (!response.ok) {
        //   throw new Error("Network response was not ok");
        // }
        // console.log(response[1].Sections[0])
        const jsonData = await response;
        setData(jsonData);
        setFormData({
          Page_Title: jsonData[0].Page_Title,
          Page_Link: jsonData[0].Page_Link,
          Meta_Name: jsonData[0].Meta_Name,
          Meta_Description: jsonData[0].Meta_Description,
          Sections: [
            {
              PH_Id: 12,
              Head_Title: jsonData[0].Sections.find((data) => data.PH_Id === 12)
                .Head_Title,
              Content_Title: jsonData[0].Sections.find(
                (data) => data.PH_Id === 12
              ).Content_Title,
              Content: jsonData[0].Sections.find((data) => data.PH_Id === 12)
                .Content,
              Image: null,
              More_Detail: [],
            },
            {
              PH_Id: 13,
              Head_Title: jsonData[0].Sections.find((data) => data.PH_Id === 13)
                .Head_Title,
              Content_Title: jsonData[0].Sections.find(
                (data) => data.PH_Id === 13
              ).Content_Title,
              Content: jsonData[0].Sections.find((data) => data.PH_Id === 13)
                .Content,
              Image: null,
              More_Detail: [
                {
                  PHD_Id: 54,
                  PH_Id: 13,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 54).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 54).Content,
                  Image: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 54).Image,
                  Link: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 54).Link,
                  created_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 54).created_at,
                  updated_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 54).updated_at,
                },
                {
                  PHD_Id: 55,
                  PH_Id: 13,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 55).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 55).Content,
                  Image: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 55).Image,
                  Link: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 55).Link,
                  created_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 55).created_at,
                  updated_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 55).updated_at,
                },
                {
                  PHD_Id: 56,
                  PH_Id: 13,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 56).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 56).Content,
                  Image: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 56).Image,
                  Link: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 56).Link,
                  created_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 56).created_at,
                  updated_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 56).updated_at,
                },
                {
                  PHD_Id: 57,
                  PH_Id: 13,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 57).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 57).Content,
                  Image: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 57).Image,
                  Link: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 57).Link,
                  created_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 57).created_at,
                  updated_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 13
                  ).More_Detail.find((data) => data.PHD_Id === 57).updated_at,
                },
              ],
            },
            {
              PH_Id: 14,
              Head_Title: jsonData[0].Sections.find((data) => data.PH_Id === 14)
                .Head_Title,
              Content_Title: jsonData[0].Sections.find(
                (data) => data.PH_Id === 14
              ).Content_Title,
              Content: jsonData[0].Sections.find((data) => data.PH_Id === 14)
                .Content,
              Image: null,
              More_Detail: [
                {
                  PHD_Id: 58,
                  PH_Id: 14,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 14
                  ).More_Detail.find((data) => data.PHD_Id === 58).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 14
                  ).More_Detail.find((data) => data.PHD_Id === 58).Content,
                  Image: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 14
                  ).More_Detail.find((data) => data.PHD_Id === 58).Image,
                  Link: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 14
                  ).More_Detail.find((data) => data.PHD_Id === 58).Link,
                  created_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 14
                  ).More_Detail.find((data) => data.PHD_Id === 58).created_at,
                  updated_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 14
                  ).More_Detail.find((data) => data.PHD_Id === 58).updated_at,
                },
                {
                  PHD_Id: 59,
                  PH_Id: 14,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 14
                  ).More_Detail.find((data) => data.PHD_Id === 59).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 14
                  ).More_Detail.find((data) => data.PHD_Id === 59).Content,
                  Image: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 14
                  ).More_Detail.find((data) => data.PHD_Id === 59).Image,
                  Link: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 14
                  ).More_Detail.find((data) => data.PHD_Id === 59).Link,
                  created_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 14
                  ).More_Detail.find((data) => data.PHD_Id === 59).created_at,
                  updated_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 14
                  ).More_Detail.find((data) => data.PHD_Id === 59).updated_at,
                },
                {
                  PHD_Id: 60,
                  PH_Id: 14,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 14
                  ).More_Detail.find((data) => data.PHD_Id === 60).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 14
                  ).More_Detail.find((data) => data.PHD_Id === 60).Content,
                  Image: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 14
                  ).More_Detail.find((data) => data.PHD_Id === 60).Image,
                  Link: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 14
                  ).More_Detail.find((data) => data.PHD_Id === 60).Link,
                  created_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 14
                  ).More_Detail.find((data) => data.PHD_Id === 60).created_at,
                  updated_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 14
                  ).More_Detail.find((data) => data.PHD_Id === 60).updated_at,
                },
              ],
            },
            {
              PH_Id: 15,
              Head_Title: jsonData[0].Sections.find((data) => data.PH_Id === 15)
                .Head_Title,
              Content_Title: jsonData[0].Sections.find(
                (data) => data.PH_Id === 15
              ).Content_Title,
              Content: jsonData[0].Sections.find((data) => data.PH_Id === 15)
                .Content,
              Image: null,
              More_Detail: [
                {
                  PHD_Id: 61,
                  PH_Id: 15,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 61).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 61).Content,
                  Image: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 61).Image,
                  Link: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 61).Link,
                  created_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 61).created_at,
                  updated_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 61).updated_at,
                },
                {
                  PHD_Id: 62,
                  PH_Id: 15,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 62).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 62).Content,
                  Image: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 62).Image,
                  Link: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 62).Link,
                  created_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 62).created_at,
                  updated_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 62).updated_at,
                },
                {
                  PHD_Id: 63,
                  PH_Id: 15,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 63).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 63).Content,
                  Image: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 63).Image,
                  Link: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 63).Link,
                  created_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 63).created_at,
                  updated_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 63).updated_at,
                },
                {
                  PHD_Id: 64,
                  PH_Id: 15,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 64).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 64).Content,
                  Image: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 64).Image,
                  Link: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 64).Link,
                  created_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 64).created_at,
                  updated_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 15
                  ).More_Detail.find((data) => data.PHD_Id === 64).updated_at,
                },
              ],
            },
          ],
        });
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div
      className="dashboard-component-container"
      // style={{ border: "1px solid #000" }}
    >
      <div className="pageTitle">
        <h2
          style={{
            width: "100%",
            backgroundColor: "var(--color-primary)",
            padding: "10px",
            color: "white",
            fontSize: "26px",
          }}
        >
          <span className="pageTitle-highlight">
            {data[0].Page_Title + " Page"}
          </span>
        </h2>
        <form className="form">
          <div
            className="formGroup"
            style={{ gap: "10px", marginBottom: "20px" }}
          >
            <TextField
              id="Page_Title"
              label="Page Title"
              name="Page_Title"
              value={formData.Page_Title}
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="Meta_Name"
              label="Meta Name"
              name="Meta_Name"
              value={formData.Meta_Name}
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="Page_Link"
              label="Page Link"
              name="Page_Link"
              value={formData.Page_Link}
              onChange={handleInputChange}
              disabled
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="Meta_Description"
              label="Meta Description"
              name="Meta_Description"
              value={formData.Meta_Description}
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
          </div>

          {/* Why Choose  SECTION */}

          <div
            className="formGroup"
            style={{
              gap: "10px",
              marginBottom: "20px",
            }}
          >
            <h2
              style={{
                fontSize: "16px",
                width: "100%",
                backgroundColor: "var(--color-primary)",
                padding: "10px",
                color: "white",
              }}
            >
              <span
                style={{
                  textTransform: "uppercase",
                }}
                className="pageTitle-highlight"
              >
                SECTION - Why Choose Us
              </span>
            </h2>
            <TextField
              id="Choose"
              label="Head Title"
              name="Head_Title"
              value={
                formData.Sections.find((data) => data.PH_Id === 12).Head_Title
              }
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="Choose"
              label="Content Title"
              name="Content_Title"
              value={
                formData.Sections.find((data) => data.PH_Id === 12)
                  .Content_Title
              }
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="Choose"
              label="Content"
              multiline
              rows={4}
              variant="outlined"
              name="Content"
              value={
                formData.Sections.find((data) => data.PH_Id === 12).Content
              }
              onChange={handleInputChange}
              style={{ marginTop: "5px", width: "100%" }}
            />
          </div>

          {/* Brief overview SECTION */}

          <div
            className="formGroup"
            style={{
              gap: "10px",
              marginBottom: "20px",
            }}
          >
            <h2
              style={{
                fontSize: "16px",
                width: "100%",
                backgroundColor: "var(--color-primary)",
                padding: "10px",
                color: "white",
              }}
            >
              <span
                style={{
                  textTransform: "uppercase",
                }}
                className="pageTitle-highlight"
              >
                SECTION - Brief overview
              </span>
            </h2>
            <TextField
              id="Brief"
              label="Head Title"
              name="Head_Title"
              value={
                formData.Sections.find((data) => data.PH_Id === 13).Head_Title
              }
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="Brief"
              label="Content Title"
              name="Content_Title"
              value={
                formData.Sections.find((data) => data.PH_Id === 13)
                  .Content_Title
              }
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="Brief"
              label="Content"
              multiline
              rows={4}
              variant="outlined"
              name="Content"
              value={
                formData.Sections.find((data) => data.PH_Id === 13).Content
              }
              onChange={handleInputChange}
              style={{ marginTop: "5px", width: "100%" }}
            />

            <div className="sectionItems">
              {data[0].Sections[1].More_Detail.map((row, index) => (
                <div key={row.PHD_Id} className="sectionItem">
                  <h2
                    style={{
                      fontSize: "14px",
                      width: "100%",
                      backgroundColor: "#44596E",
                      padding: "10px",
                      color: "white",
                      marginTop: "5px",
                    }}
                  >
                    <span
                      style={{
                        textTransform: "uppercase",
                      }}
                      className="pageTitle-highlight"
                    >
                      Brief - {index + 1}
                    </span>
                  </h2>
                  <TextField
                    id={row.PHD_Id.toString()}
                    label="Title"
                    name="Title"
                    value={
                      formData.Sections.find(
                        (data) => data.PH_Id === 13
                      ).More_Detail.find((data) => data.PHD_Id === row.PHD_Id)
                        .Title
                    }
                    onChange={briefChange}
                    variant="outlined"
                    style={{
                      marginTop: "5px",
                      width: "100%",
                    }}
                  />

                  <TextField
                    id={row.PHD_Id.toString()}
                    label="Content"
                    name="Content"
                    value={
                      formData.Sections.find(
                        (data) => data.PH_Id === 13
                      ).More_Detail.find((data) => data.PHD_Id === row.PHD_Id)
                        .Content
                    }
                    multiline
                    rows={4}
                    onChange={briefChange}
                    variant="outlined"
                    style={{
                      marginTop: "5px",
                      width: "100%",
                    }}
                  />
                  <img
                    id={row.PHD_Id.toString()}
                    onChange={briefChange}
                    src={
                      formData.Sections.find(
                        (data) => data.PH_Id === 13
                      ).More_Detail.find((data) => data.PHD_Id === row.PHD_Id)
                        .Image
                    }
                    alt={row.PHD_Id}
                    style={{
                      width: "100%",
                      marginTop: "5px",
                      objectFit: "contain",
                      height: "300px",
                    }}
                  />

                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    // onChange={handleImageChange}
                    style={{ marginTop: "5px", backgroundColor: "#44596E" }}
                    startIcon={<MdUpload />}
                  >
                    Upload Picture
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(event) =>
                        handleImageChange(event, row.PHD_Id.toString())
                      }
                    />
                  </Button>
                </div>
              ))}
            </div>
          </div>

          {/* State-of-Art Machinery SECTION */}

          <div
            className="formGroup"
            style={{
              gap: "10px",
              marginBottom: "20px",
            }}
          >
            <h2
              style={{
                fontSize: "16px",
                width: "100%",
                backgroundColor: "var(--color-primary)",
                padding: "10px",
                color: "white",
              }}
            >
              <span
                style={{
                  textTransform: "uppercase",
                }}
                className="pageTitle-highlight"
              >
                SECTION - State-of-Art Machinery
              </span>
            </h2>
            <TextField
              id="State"
              label="Head Title"
              name="Head_Title"
              value={
                formData.Sections.find((data) => data.PH_Id === 14).Head_Title
              }
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="State"
              label="Content Title"
              name="Content_Title"
              value={
                formData.Sections.find((data) => data.PH_Id === 14)
                  .Content_Title
              }
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="State"
              label="Content"
              multiline
              rows={4}
              variant="outlined"
              name="Content"
              value={
                formData.Sections.find((data) => data.PH_Id === 14).Content
              }
              onChange={handleInputChange}
              style={{ marginTop: "5px", width: "100%" }}
            />

            <div className="sectionItems">
              {data[0].Sections[2].More_Detail.map((row, index) => (
                <div key={row.PHD_Id} className="sectionItem">
                  <h2
                    style={{
                      fontSize: "14px",
                      width: "100%",
                      backgroundColor: "#44596E",
                      padding: "10px",
                      color: "white",
                      marginTop: "5px",
                    }}
                  >
                    <span
                      style={{
                        textTransform: "uppercase",
                      }}
                      className="pageTitle-highlight"
                    >
                      Machinery - {index + 1}
                    </span>
                  </h2>
                  <TextField
                    id={row.PHD_Id.toString()}
                    label="Title"
                    name="Title"
                    value={
                      formData.Sections.find(
                        (data) => data.PH_Id === 14
                      ).More_Detail.find((data) => data.PHD_Id === row.PHD_Id)
                        .Title
                    }
                    onChange={stateChange}
                    variant="outlined"
                    style={{
                      marginTop: "5px",
                      width: "100%",
                    }}
                  />

                  <img
                    id={row.PHD_Id.toString()}
                    onChange={stateChange}
                    src={
                      formData.Sections.find(
                        (data) => data.PH_Id === 14
                      ).More_Detail.find((data) => data.PHD_Id === row.PHD_Id)
                        .Image
                    }
                    alt={row.PHD_Id}
                    style={{
                      width: "100%",
                      marginTop: "5px",
                      objectFit: "contain",
                      height: "300px",
                    }}
                  />

                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    // onChange={handleImageChange}
                    style={{ marginTop: "5px", backgroundColor: "#44596E" }}
                    startIcon={<MdUpload />}
                  >
                    Upload Picture
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(event) =>
                        handleImageChange2(event, row.PHD_Id.toString())
                      }
                    />
                  </Button>
                </div>
              ))}
            </div>
          </div>

          {/* Crafting Excellance SECTION */}

          <div
            className="formGroup"
            style={{
              gap: "10px",
              marginBottom: "20px",
            }}
          >
            <h2
              style={{
                fontSize: "16px",
                width: "100%",
                backgroundColor: "var(--color-primary)",
                padding: "10px",
                color: "white",
              }}
            >
              <span
                style={{
                  textTransform: "uppercase",
                }}
                className="pageTitle-highlight"
              >
                SECTION - Crafting Excellance
              </span>
            </h2>
            <TextField
              id="Crafting"
              label="Head Title"
              name="Head_Title"
              value={
                formData.Sections.find((data) => data.PH_Id === 15).Head_Title
              }
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="Crafting"
              label="Content Title"
              name="Content_Title"
              value={
                formData.Sections.find((data) => data.PH_Id === 15)
                  .Content_Title
              }
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="Crafting"
              label="Content"
              multiline
              rows={4}
              variant="outlined"
              name="Content"
              value={
                formData.Sections.find((data) => data.PH_Id === 15).Content
              }
              onChange={handleInputChange}
              style={{ marginTop: "5px", width: "100%" }}
            />

            <div className="sectionItems">
              {data[0].Sections[3].More_Detail.map((row, index) => (
                <div key={row.PHD_Id} className="sectionItem">
                  <h2
                    style={{
                      fontSize: "14px",
                      width: "100%",
                      backgroundColor: "#44596E",
                      padding: "10px",
                      color: "white",
                      marginTop: "5px",
                    }}
                  >
                    <span
                      style={{
                        textTransform: "uppercase",
                      }}
                      className="pageTitle-highlight"
                    >
                      Process - {index + 1}
                    </span>
                  </h2>
                  <TextField
                    id={row.PHD_Id.toString()}
                    label="Title"
                    name="Title"
                    value={
                      formData.Sections.find(
                        (data) => data.PH_Id === 15
                      ).More_Detail.find((data) => data.PHD_Id === row.PHD_Id)
                        .Title
                    }
                    onChange={craftingChange}
                    variant="outlined"
                    style={{
                      marginTop: "5px",
                      width: "100%",
                    }}
                  />

                  <TextField
                    id={row.PHD_Id.toString()}
                    label="Content"
                    name="Content"
                    multiline
              rows={4}
                    value={
                      formData.Sections.find(
                        (data) => data.PH_Id === 15
                      ).More_Detail.find((data) => data.PHD_Id === row.PHD_Id)
                        .Content
                    }
                    onChange={craftingChange}
                    variant="outlined"
                    style={{
                      marginTop: "5px",
                      width: "100%",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="addButton">
            <Button
              style={{
                border: "1px solid var(--color-primary)",
                color: "var(--color-primary)",
              }}
              variant="outlined"
              // startIcon={<FaCirclePlus />}
              onClick={() => updatePage()}
            >
              Save Data
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default HomeTextiles;
