import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { getPages } from "../../../services/dashboard.service";
import { Link, Outlet } from "react-router-dom";
function Pages() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPages();
        const jsonData = await response;
        setData(jsonData);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  return (
    <React.Fragment>
      <div className="dashboard-component-container">
        <div className="menu-cards">
          {data.map((item) => (
            <Link
              key={item.Page_Id}
              to={item.Page_Link.replace(
                "https://unionapi.kidznteen.pk/public/",
                ""
              ).toLowerCase()}
              style={{
                textDecoration: "none",
                // border: "1px solid var(--color-primary)",
                color: "var(--color-primary)",
                // width: "clamp(300px, 100%, 300px)",
                height: "40px",
                fontSize: "14px",
              }}
            >
              <Button
                variant="outlined"
                size="small"
                className="menu-card"
                style={{
                  border: "1px solid var(--color-primary)",
                  color: "var(--color-primary)",
                  width: "100%",
                  height: "40px",
                  fontSize: "14px",
                }}
              >
                {item.Page_Title}
              </Button>
            </Link>
          ))}
        </div>
        <Outlet></Outlet>
      </div>
    </React.Fragment>
  );
}

export default Pages;
