import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { TablePagination } from "@mui/material";
import { styled } from "@mui/material/styles";

// Icons
import { FaCirclePlus } from "react-icons/fa6";
import { FaPen } from "react-icons/fa";
import { MdUpload } from "react-icons/md";

// Form Feilds
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";

import {
  createProduct,
  updateProduct,
  getProduct,
} from "../../../services/dashboard.service";

function Product() {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [zoomedImages, setZoomedImages] = useState({});

  const toggleZoom = (imageKey) => {
    setZoomedImages((prevState) => ({
      ...prevState,
      [imageKey]: !prevState[imageKey],
    }));
  };

  const [editId, setEditId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    Name: "",
    Desc: "",
    Cat_Id: "1",
    Image: "",
    IsActive: true,
    IsDelete: false,
  });

  const creatProduct = () => {
    setCreate(true);
    editData();
  };

  const editData = (Id) => {
    const selectedRow = data.find((data) => data.Prod_Id === Id);
    if (selectedRow) {
      setFormData({
        Name: selectedRow.Name,
        Desc: selectedRow.Desc,
        Cat_Id: selectedRow.Cat_Id,
        IsActive: selectedRow.IsActive,
        IsDelete: selectedRow.IsDelete,
        Image: selectedRow.Image,
      });
    }
    setEditId(Id);
    setOpenDialog(true);
  };

  const closeEditForm = () => {
    setChangeImage(false);
    setImagePreview(null);
    setFormData({
      Name: "",
      Desc: "",
      Cat_Id: "1",
      Image: "",
      IsActive: true,
      IsDelete: false,
    });
    setEditId(null);
    setOpenDialog(false);
    setCreate(false);
    setMessageError(false);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: inputValue,
    });
  };

  // const [selectedImage, setSelectedImage] = useState(null);
  const [changeImage, setChangeImage] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (event) => {
    setChangeImage(true);
    const file = event.target.files[0];

    // Display image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
      setFormData({
        ...formData,
        Image: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    const inputValue = value;
    setFormData({
      ...formData,
      [name]: inputValue,
    });
  };

  const [create, setCreate] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const formDataWithCreatedBy = {
    ...formData,
    IsEditable: true,
    CreatedBy: "1", //Get ID from Local Storage
  };
  const formDataWithModifyBy = {
    ...formData,
    IsEditable: true,
    ModifyBy: "1", //Get ID from Local Storage
  };

  const handleSubmit = async () => {
    if (
      formData.Cat_Id === "" ||
      formData.Desc === "" ||
      formData.Name === "" ||
      formData.Image === ""
    ) {
      setMessageError(true);
      // console.log(formData);
    } else {
      if (create) {
        try {
          const response = await createProduct(formDataWithCreatedBy);
          // if (!response.ok) {
          //   throw new Error("Network response was not ok");
          // }
          console.log(response);
          closeEditForm();
          const response2 = await getProduct();
          const jsonData = await response2;
          setData(jsonData);
        } catch (error) {
          console.error("Error adding product:", error);
        }
      } else {
        try {
          const response = await updateProduct(formDataWithModifyBy, editId);
          console.log(response);
          // Handle success response
          // console.log("Product updated successfully");
          // Optionally, you can fetch updated data from the server and update state
          closeEditForm();
          const response2 = await getProduct();
          const jsonData = await response2;
          setData(jsonData);
        } catch (error) {
          console.error("Error updating product:", error);
        }
        setChangeImage(false);
        setImagePreview(null);
        setFormData({
          Name: "",
          Desc: "",
          Cat_Id: "1",
          Image: "",
          IsActive: true,
          IsDelete: false,
        });
        // setEditId(null);
        setOpenDialog(false);
        setCreate(false);
        setMessageError(false);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProduct();
        // if (!response.ok) {
        //   throw new Error("Network response was not ok");
        // }
        const jsonData = await response;
        setData(jsonData);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="dashboard-component-container">
      <div className="addButton">
        <Button
          style={{
            border: "1px solid var(--color-primary)",
            color: "var(--color-primary)",
          }}
          variant="outlined"
          startIcon={<FaCirclePlus />}
          onClick={() => creatProduct()}
        >
          Add Product
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">Category Name</TableCell>
              <TableCell align="center">Product Name</TableCell>
              {/* <TableCell align="center">Desc</TableCell> */}
              <TableCell align="center">Image</TableCell>
              <TableCell align="center">Active</TableCell>
              {/* <TableCell align="center">Delete</TableCell> */}
              <TableCell align="center">Created On</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  key={row.Prod_Id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {row.Prod_Id}
                  </TableCell>
                  <TableCell align="left">{row.Category_Name}</TableCell>
                  <TableCell align="left">{row.Name}</TableCell>
                  {/* <TableCell align="left">{row.Desc}</TableCell> */}
                  <TableCell align="left">
                    <img
                      className={
                        zoomedImages[row.Prod_Id]
                          ? "productTableImage zoomed"
                          : "productTableImage"
                      }
                      onClick={() => toggleZoom(row.Prod_Id)}
                      src={row.Image}
                      alt=""
                    />
                  </TableCell>
                  <TableCell align="center">
                    {row.IsActive.toString()}
                  </TableCell>
                  {/* <TableCell align="center">
                    {row.IsDelete.toString()}
                  </TableCell> */}
                  <TableCell align="center">
                    {row.CreatedOn.toString()}
                  </TableCell>
                  <TableCell align="center">
                    <button
                      className="Edit-Button"
                      onClick={() => editData(row.Prod_Id)}
                    >
                      <FaPen></FaPen>
                    </button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={openDialog} onClose={closeEditForm} className="Dialog">
        <DialogTitle>
          {create ? `Create Product` : `Edit Product ID: ${editId}`}
        </DialogTitle>
        <DialogContent className="DialogContent" style={{ overflow: "hidden" }}>
          <form className="form">
            <div className="formGroup">
              <TextField
                id="productName"
                label="Product Name"
                name="Name"
                value={formData.Name}
                onChange={handleInputChange}
                variant="outlined"
                style={{ marginTop: "5px", width: "100%" }}
              />
              <TextField
                id="desc"
                label="Desc"
                multiline
                rows={4}
                variant="outlined"
                name="Desc"
                value={formData.Desc}
                onChange={handleInputChange}
                style={{ marginTop: "5px", width: "100%" }}
              />
              <FormControl fullWidth>
                <InputLabel style={{ marginTop: "5px" }} id="Category">
                  Category{" "}
                </InputLabel>
                <Select
                  labelId="Category"
                  id="Category"
                  label="Category"
                  value={formData.Cat_Id}
                  onChange={handleSelectChange}
                  name="Cat_Id"
                  style={{ marginTop: "5px" }}
                >
                  <MenuItem value="">Select Category</MenuItem>
                  <MenuItem value="1">Kids Room</MenuItem>
                  <MenuItem value="2">Teen Age</MenuItem>
                  <MenuItem value="3">Master Bedroom</MenuItem>
                  <MenuItem value="4">Hotel</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                label="Active"
                style={{ marginTop: "5px" }}
                control={
                  <Checkbox
                    inputProps={{ "aria-label": "controlled" }}
                    id="isActive"
                    name="IsActive"
                    checked={formData.IsActive}
                    onChange={handleInputChange}
                  />
                }
              />

              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                onChange={handleImageChange}
                style={{ marginTop: "5px" }}
                startIcon={<MdUpload />}
              >
                Upload Picture
                <VisuallyHiddenInput type="file" />
              </Button>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {changeImage ? (
                  <img
                    src={changeImage ? imagePreview : formData.Image}
                    alt=""
                    style={{
                      width: "100%",
                      maxWidth: "500px",
                      marginTop: "5px",
                      objectFit: "cover",
                      height: "500px",
                    }}
                  />
                ) : (
                  <img
                    src={changeImage ? imagePreview : formData.Image}
                    alt=""
                    style={{
                      width: "100%",
                      maxWidth: "500px",
                      marginTop: "5px",
                      objectFit: "cover",
                      height: create ? "0px" : "500px",
                    }}
                  />
                )}
              </div>
              {messageError ? (
                <p className="errorMessage">Please enter all the fields</p>
              ) : (
                <p></p>
              )}

              {/* <label htmlFor="productName">Product Name:</label>
              <input
                type="text"
                id="productName"
                name="Name"
                value={formData.Name}
                onChange={handleInputChange}
              /> */}
            </div>
            {/* <div className="formGroup">
              <label htmlFor="desc">Desc:</label>
              <textarea
                type="text"
                id="desc"
                name="Desc"
                value={formData.Desc}
                onChange={handleInputChange}
              />
            </div> */}
            {/* <div className="formGroup">
              <label htmlFor="Category">Category:</label>
              <select
                id="Category"
                name="Cat_Id"
                value={formData.Cat_Id}
                onChange={handleSelectChange}
              >
                <option value="">Select Category</option>
                <option value="1">Kids Room</option>
                <option value="2">Teen Age</option>
                <option value="3">Master Bedroom</option>
                <option value="4">Hotel</option>
            
              </select>
            </div> */}
            {/* <div className="formGroup">
              <label htmlFor="isActive">Active:</label>
              <input
                type="checkbox"
                id="isActive"
                name="IsActive"
                checked={formData.IsActive}
                onChange={handleInputChange}
              />
            </div> */}
            {/* <div className="formGroup">
              <label htmlFor="IsDelete">Delete:</label>
              <input
                type="checkbox"
                id="IsDelete"
                name="IsDelete"
                checked={formData.IsDelete}
                onChange={handleInputChange}
              />
            </div> */}

            <div className="formGroup">
              {/* <label htmlFor="imageSelect">Select Image:</label> */}

              {/* <input
                type="file"
                id="imageSelect"
                accept="image/*" // restricts selection to image files
                onChange={handleImageChange}
              /> */}
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditForm}>Cancel</Button>
          <Button onClick={handleSubmit}> {create ? "Create" : "Save"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Product;
