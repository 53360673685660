import React from "react";
// import { Navigate } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
// import NoInternetConnection from "../pages/NoInternetConnection/Page-404";
// Dashboard
import Dashboard from "../pages/Dashboard/index";
// import Pages from "../pages/Dashboard/Pages/Pages";
import ForgetPassword from "../pages/Authentication/ForgetPassword";

const authProtectedRoutes = [
  { path: "/", component: <Dashboard /> },
];

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPassword /> },
];

export { authProtectedRoutes, publicRoutes };
