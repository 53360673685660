import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { TablePagination } from "@mui/material";

// Icons
// import { FaCirclePlus } from "react-icons/fa6";
import { FaPen } from "react-icons/fa";

// Form Feilds
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import {
  // createSliderType,
  getSliderType,
  updateSliderType,
} from "../../../services/dashboard.service";

function SliderType() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [editId, setEditId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    Title: "",
    IsActive: true,
    IsDelete: false,
    IsEditable: false,
  });

  // const creatProduct = () => {
  //   setCreate(true);
  //   editData();
  // };

  const editData = (Id) => {
    const selectedRow = data.find((data) => data.Slider_Type_Id === Id);
    if (selectedRow) {
      setFormData({
        Title: selectedRow.Title,
        IsActive: selectedRow.IsActive,
        IsDelete: selectedRow.IsDelete,
        IsEditable: selectedRow.IsEditable,
      });
    }
    setEditId(Id);
    setOpenDialog(true);
  };

  const closeEditForm = () => {
    setFormData({
      Title: "",
      IsActive: true,
      IsDelete: false,
      IsEditable: false,
    });
    setEditId(null);
    setOpenDialog(false);
    // setCreate(false);
    setMessageError(false);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: inputValue,
    });
  };

  // const [create, setCreate] = useState(false);
  const [messageError, setMessageError] = useState(false);

  // const formDataWithCreatedBy = {
  //   ...formData,
  //   CreatedBy: "1", //Get ID from Local Storage
  // };

  const formDataWithModifyBy = {
    ...formData,
    IsEditable: true,
    ModifyBy: "1", //Get ID from Local Storage
  };

  const handleSubmit = async () => {
    if (formData.Title === "") {
      setMessageError(true);
    // } else {
    //   if (create) {
    //     try {
    //       const response = await createSliderType(formDataWithCreatedBy);
    //       const response2 = await getSliderType();
    //       // if (!response.ok) {
    //       //   throw new Error("Network response was not ok");
    //       // }
    //       const jsonData = await response2;
    //       setData(jsonData);
    //       // if (!response.ok) {
    //       //   throw new Error("Network response was not ok");
    //       // }
    //       console.log(response);
    //       closeEditForm();
    //     } catch (error) {
    //       console.error("Error adding slider type:", error);
    //     }
      } else {
        try {
          // console.log(formDataWithModifyBy);
          const response = await updateSliderType(formDataWithModifyBy, editId);
          const response2 = await getSliderType();
          // if (!response.ok) {
          //   throw new Error("Network response was not ok");
          // }
          const jsonData = await response2;
          setData(jsonData);
          // if (!response.ok) {
          //   throw new Error("Network response was not ok");
          // }
          // Handle success response
          // console.log(response);
          // Optionally, you can fetch updated data from the server and update state
          closeEditForm();
        } catch (error) {
          console.error("Error updating slider type:", error);
        }
        setFormData({
          Title: "",
          IsActive: true,
          IsDelete: false,
          IsEditable: false,
        });
        // setEditId(null);
        setOpenDialog(false);
        // setCreate(false);
        setMessageError(false);
      }
    // }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getSliderType();
        // if (!response.ok) {
        //   throw new Error("Network response was not ok");
        // }
        const jsonData = await response;
        setData(jsonData);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="dashboard-component-container">
      {/* <div className="addButton">
        <Button
          style={{
            border: "1px solid var(--color-primary)",
            color: "var(--color-primary)",
          }}
          variant="outlined"
          startIcon={<FaCirclePlus />}
          onClick={() => creatProduct()}
        >
          Add Slider Type
        </Button>
      </div> */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">Title</TableCell>
              <TableCell align="center">Active / Inactive</TableCell>
              <TableCell align="center">Created On</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  key={row.Slider_Type_Id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {row.Slider_Type_Id}
                  </TableCell>
                  <TableCell align="left">{row.Title}</TableCell>

                  <TableCell align="center">
                    {row.IsActive ? "Active" : "Inactive"}
                  </TableCell>

                  <TableCell align="center">
                    {row.CreatedOn.toString()}
                  </TableCell>
                  <TableCell align="center">
                    <button
                      className="Edit-Button"
                      onClick={() => editData(row.Slider_Type_Id)}
                    >
                      <FaPen></FaPen>
                    </button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={openDialog} onClose={closeEditForm} className="Dialog">
        <DialogTitle>
          {/* {create ? `Create Slider Type` : `Edit Slider Type ID: ${editId}`} */}
         `Edit Slider Type ID: ${editId}
        </DialogTitle>
        <DialogContent className="DialogContent" style={{ overflow: "hidden" }}>
          <form className="form">
            <div className="formGroup">
              <TextField
                id="Title"
                label="Title"
                name="Title"
                value={formData.Title}
                onChange={handleInputChange}
                variant="outlined"
                style={{ marginTop: "5px", width: "100%" }}
              />

              <FormControlLabel
                label="Active"
                style={{ marginTop: "5px" }}
                control={
                  <Checkbox
                    inputProps={{ "aria-label": "controlled" }}
                    id="isActive"
                    name="IsActive"
                    checked={formData.IsActive}
                    onChange={handleInputChange}
                  />
                }
              />
              {messageError ? (
                <p className="errorMessage">Please enter all the fields</p>
              ) : (
                <p></p>
              )}
            </div>

            <div className="formGroup"></div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditForm}>Cancel</Button>
          {/* <Button onClick={handleSubmit}> {create ? "Create" : "Save"}</Button> */}
          <Button onClick={handleSubmit}> Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SliderType;
