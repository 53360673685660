import axios from "axios";

const API_URL = "https://unionapi.kidznteen.pk/public/";

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function post(url, data) {
  try {
    const response = await axiosApi.post(API_URL + url, data);
    if (response.status >= 200 && response.status <= 299) return response.data;
    throw response.data;
  } catch (error) {
    return error;
  }
}

export async function put(url, data) {
  return axiosApi
    .put(API_URL + url, data)
    .then((response) => response.data);
}

export async function del(url) {
  return await axiosApi.delete(url).then((response) => response.data);
}

export async function get(url) {
  return await axiosApi
    .get(API_URL + url)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch((err) => {
      return err;
    });
}
