import React, { useState, useEffect } from "react";
import { getPage, updateHome } from "../../../services/dashboard.service";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { MdUpload } from "react-icons/md";

function History() {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState();

  const handleInputChange = (e) => {
    const { name, value, id } = e.target;
    const inputValue = value;
    setFormData({
      ...formData,
      [name]: inputValue,
    });

    switch (id) {
      case "History":
        const aboutSectionIndex = formData.Sections.findIndex(
          (section) => section.PH_Id === 7
        );

        formData.Sections[aboutSectionIndex][name] = inputValue;
        break;

      case "Establishment":
        const timelineSectionIndex = formData.Sections.findIndex(
          (section) => section.PH_Id === 8
        );

        formData.Sections[timelineSectionIndex][name] = inputValue;
        break;

      case "Mission":
        const productSectionIndex = formData.Sections.findIndex(
          (section) => section.PH_Id === 9
        );

        formData.Sections[productSectionIndex][name] = inputValue;
        break;

      default:
        console.error("Invalid section ID");
    }
  };

  const productChange = (e) => {
    const { name, value, id } = e.target;

    var currentdate = new Date();

    currentdate =
      currentdate.getFullYear() +
      "-" +
      (currentdate.getMonth() + 1) +
      "-" +
      currentdate.getDate() +
      " " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds();

    const Index = formData.Sections.findIndex((section) => section.PH_Id === 9);

    const detailIndex = formData.Sections[Index].More_Detail.findIndex(
      (detail) => detail.PHD_Id === parseInt(id)
    );

    if (Index !== -1 && detailIndex !== -1) {
      const updatedFormData = { ...formData };
      updatedFormData.Sections[Index].More_Detail[detailIndex][name] = value;
      updatedFormData.Sections[Index].More_Detail[detailIndex].updated_at =
        currentdate;
      setFormData(updatedFormData);
    } else {
      console.error(
        "Mission and Vission section or More_Detail object not found in form data"
      );
    }
  };
  const handleImageChange = (event, id) => {
    const file = event.target.files[0];

    // Display image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      const Index = formData.Sections.findIndex(
        (section) => section.PH_Id === 9
      );

      const detailIndex = formData.Sections[Index].More_Detail.findIndex(
        (detail) => detail.PHD_Id === parseInt(id)
      );
      const updatedFormData = { ...formData };
      updatedFormData.Sections[Index].More_Detail[detailIndex].Image =
        reader.result;
      setFormData(updatedFormData);
    };
    reader.readAsDataURL(file);
  };

  const updatePage = async () => {
    try {
      await updateHome(formData, 2);
    } catch (error) {
      console.error("Error updating page:", error);
    }

    // console.log(formData)
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPage(2);
        // if (!response.ok) {
        //   throw new Error("Network response was not ok");
        // }
        const jsonData = await response;
        setData(jsonData);
        setFormData({
          Page_Title: jsonData[0].Page_Title,
          Page_Link: jsonData[0].Page_Link,
          Meta_Name: jsonData[0].Meta_Name,
          Meta_Description: jsonData[0].Meta_Description,
          Sections: [
            {
              PH_Id: 7,
              Head_Title: jsonData[0].Sections.find((data) => data.PH_Id === 7)
                .Head_Title,
              Content_Title: jsonData[0].Sections.find(
                (data) => data.PH_Id === 7
              ).Content_Title,
              Content: jsonData[0].Sections.find((data) => data.PH_Id === 7)
                .Content,
              Image: null,
              More_Detail: [],
            },
            {
              PH_Id: 8,
              Head_Title: jsonData[0].Sections.find((data) => data.PH_Id === 8)
                .Head_Title,
              Content_Title: jsonData[0].Sections.find(
                (data) => data.PH_Id === 8
              ).Content_Title,
              Content: jsonData[0].Sections.find((data) => data.PH_Id === 8)
                .Content,
              Image: null,
              More_Detail: [],
            },
            {
              PH_Id: 9,
              Head_Title: jsonData[0].Sections.find((data) => data.PH_Id === 9)
                .Head_Title,
              Content_Title: jsonData[0].Sections.find(
                (data) => data.PH_Id === 9
              ).Content_Title,
              Content: jsonData[0].Sections.find((data) => data.PH_Id === 9)
                .Content,
              Image: null,
              More_Detail: [
                {
                  PHD_Id: 26,
                  PH_Id: 9,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 26).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 26).Content,
                  Image: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 26).Image,
                  Link: "#",
                  created_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 26).created_at,
                  updated_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 26).updated_at,
                },
                {
                  PHD_Id: 27,
                  PH_Id: 9,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 27).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 27).Content,
                  Image: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 27).Image,
                  Link: "#",
                  created_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 27).created_at,
                  updated_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 27).updated_at,
                },
                {
                  PHD_Id: 28,
                  PH_Id: 9,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 28).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 28).Content,
                  Image: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 28).Image,
                  Link: "#",
                  created_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 28).created_at,
                  updated_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 28).updated_at,
                },
                {
                  PHD_Id: 29,
                  PH_Id: 9,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 29).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 29).Content,
                  Image: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 29).Image,
                  Link: "#",
                  created_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 29).created_at,
                  updated_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 29).updated_at,
                },
                {
                  PHD_Id: 30,
                  PH_Id: 9,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 30).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 30).Content,
                  Image: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 30).Image,
                  Link: "#",
                  created_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 30).created_at,
                  updated_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 30).updated_at,
                },
                {
                  PHD_Id: 31,
                  PH_Id: 9,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 31).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 31).Content,
                  Image: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 31).Image,
                  Link: "#",
                  created_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 31).created_at,
                  updated_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 31).updated_at,
                },
                {
                  PHD_Id: 32,
                  PH_Id: 9,
                  Title: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 32).Title,
                  Content: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 32).Content,
                  Image: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 32).Image,
                  Link: "#",
                  created_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 32).created_at,
                  updated_at: jsonData[0].Sections.find(
                    (data) => data.PH_Id === 9
                  ).More_Detail.find((data) => data.PHD_Id === 32).updated_at,
                },
              ],
            },
          ],
        });
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div
      className="dashboard-component-container"
      // style={{ border: "1px solid #000" }}
    >
      <div className="pageTitle">
        <h2
          style={{
            width: "100%",
            backgroundColor: "var(--color-primary)",
            padding: "10px",
            color: "white",
            fontSize: "26px",
          }}
        >
          <span className="pageTitle-highlight">
            {data[0].Page_Title + " Page"}
          </span>
        </h2>
        <form className="form">
          <div
            className="formGroup"
            style={{ gap: "10px", marginBottom: "20px" }}
          >
            <TextField
              id="Page_Title"
              label="Page Title"
              name="Page_Title"
              value={formData.Page_Title}
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="Meta_Name"
              label="Meta Name"
              name="Meta_Name"
              value={formData.Meta_Name}
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="Page_Link"
              label="Page Link"
              name="Page_Link"
              value={formData.Page_Link}
              onChange={handleInputChange}
              disabled
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="Meta_Description"
              label="Meta Description"
              name="Meta_Description"
              value={formData.Meta_Description}
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
          </div>

          {/* Our History SECTION */}

          <div
            className="formGroup"
            style={{ gap: "10px", marginBottom: "20px" }}
          >
            <h2
              style={{
                fontSize: "16px",
                width: "100%",
                backgroundColor: "var(--color-primary)",
                padding: "10px",
                color: "white",
              }}
            >
              <span
                style={{
                  textTransform: "uppercase",
                }}
                className="pageTitle-highlight"
              >
                SECTION - Our History
              </span>
            </h2>
            <TextField
              id="History"
              label="Head Title"
              name="Head_Title"
              value={
                formData.Sections.find((data) => data.PH_Id === 7).Head_Title
              }
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="History"
              label="Content Title"
              name="Content_Title"
              value={
                formData.Sections.find((data) => data.PH_Id === 7).Content_Title
              }
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="History"
              label="Content"
              multiline
              rows={4}
              variant="outlined"
              name="Content"
              value={formData.Sections.find((data) => data.PH_Id === 7).Content}
              onChange={handleInputChange}
              style={{ marginTop: "5px", width: "100%" }}
            />
          </div>

          {/* Establishment & Growth SECTION */}

          <div
            className="formGroup"
            style={{ gap: "10px", marginBottom: "20px" }}
          >
            <h2
              style={{
                fontSize: "16px",
                width: "100%",
                backgroundColor: "var(--color-primary)",
                padding: "10px",
                color: "white",
              }}
            >
              <span
                className="pageTitle-highlight"
                style={{
                  textTransform: "uppercase",
                }}
              >
                SECTION - Establishment & Growth{" "}
              </span>
            </h2>
            <TextField
              id="Establishment"
              label="Head Title"
              name="Head_Title"
              value={
                formData.Sections.find((data) => data.PH_Id === 8).Head_Title
              }
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="Establishment"
              label="Content Title"
              name="Content_Title"
              value={
                formData.Sections.find((data) => data.PH_Id === 8).Content_Title
              }
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="Establishment"
              label="Content"
              multiline
              rows={4}
              variant="outlined"
              name="Content"
              value={formData.Sections.find((data) => data.PH_Id === 8).Content}
              onChange={handleInputChange}
              style={{ marginTop: "5px", width: "100%" }}
            />
          </div>

          {/* Mission and Vission SECTION */}

          <div
            className="formGroup"
            style={{
              gap: "10px",
              marginBottom: "20px",
            }}
          >
            <h2
              style={{
                fontSize: "16px",
                width: "100%",
                backgroundColor: "var(--color-primary)",
                padding: "10px",
                color: "white",
              }}
            >
              <span
                style={{
                  textTransform: "uppercase",
                }}
                className="pageTitle-highlight"
              >
                SECTION - MISSION AND VISSION
              </span>
            </h2>
            <TextField
              id="Mission"
              label="Head Title"
              name="Head_Title"
              value={
                formData.Sections.find((data) => data.PH_Id === 9).Head_Title
              }
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="Mission"
              label="Content Title"
              name="Content_Title"
              value={
                formData.Sections.find((data) => data.PH_Id === 9).Content_Title
              }
              onChange={handleInputChange}
              variant="outlined"
              style={{ marginTop: "5px", width: "100%" }}
            />
            <TextField
              id="Mission"
              label="Content"
              multiline
              rows={4}
              variant="outlined"
              name="Content"
              value={formData.Sections.find((data) => data.PH_Id === 9).Content}
              onChange={handleInputChange}
              style={{ marginTop: "5px", width: "100%" }}
            />

            <div className="sectionItems">
              {data[0].Sections[2].More_Detail.map((row, index) => (
                <div key={row.PHD_Id} className="sectionItem">
                  <h2
                    style={{
                      fontSize: "14px",
                      width: "100%",
                      backgroundColor: "#44596E",
                      padding: "10px",
                      color: "white",
                      marginTop: "5px",
                    }}
                  >
                    <span
                      style={{
                        textTransform: "uppercase",
                      }}
                      className="pageTitle-highlight"
                    >
                      PRODUCT - {index + 1}
                    </span>
                  </h2>
                  <TextField
                    id={row.PHD_Id.toString()}
                    label="Title"
                    name="Title"
                    value={
                      formData.Sections.find(
                        (data) => data.PH_Id === 9
                      ).More_Detail.find((data) => data.PHD_Id === row.PHD_Id)
                        .Title
                    }
                    onChange={productChange}
                    variant="outlined"
                    style={{
                      marginTop: "5px",
                      width: "100%",
                    }}
                  />
                  <img
                    id={row.PHD_Id.toString()}
                    onChange={handleImageChange}
                    src={
                      formData.Sections.find(
                        (data) => data.PH_Id === 9
                      ).More_Detail.find((data) => data.PHD_Id === row.PHD_Id)
                        .Image
                    }
                    alt={row.PHD_Id}
                    style={{
                      width: "100%",
                      marginTop: "5px",
                      objectFit: "contain",
                      height: "300px",
                    }}
                  />

                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    // onChange={handleImageChange}
                    style={{ marginTop: "5px", backgroundColor: "#44596E" }}
                    startIcon={<MdUpload />}
                  >
                    Upload Picture
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(event) =>
                        handleImageChange(event, row.PHD_Id.toString())
                      }
                    />
                  </Button>
                </div>
              ))}
            </div>
          </div>

          <div className="addButton">
            <Button
              style={{
                border: "1px solid var(--color-primary)",
                color: "var(--color-primary)",
              }}
              variant="outlined"
              // startIcon={<FaCirclePlus />}
              onClick={() => updatePage()}
            >
              Save Data
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default History;
