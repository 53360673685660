import React, { useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// Form Feilds
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";

// Icons
import { FaPen } from "react-icons/fa";
import { updateSubMenu } from "../../../services/dashboard.service";

function Details(props) {
  const [editMenuId, setEditMenuId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [formData, setFormData] = useState({
    Name: "",
    Link: "#",
    IsActive: true,
    IsDelete: false,
    IsEditable: true,
    ModifyBy: 1,
  });

  const editData = (menuId) => {
    const selectedMenu = props.subMenuData.Sub_Menu.find(
      (menu) => menu.SubMenu_Id === menuId
    );
    if (selectedMenu) {
      setFormData({
        Name: selectedMenu.Name,
        Link: selectedMenu.Link,
        IsActive: selectedMenu.IsActive,
        IsDelete: selectedMenu.IsDelete,
        IsEditable: selectedMenu.IsEditable,
        ModifyBy: selectedMenu.ModifyBy,
      });
    }
    setEditMenuId(menuId);
    setOpenDialog(true);
  };

  const closeEditForm = () => {
    setFormData({
      Name: "",
      Link: "#",
      IsActive: true,
      IsDelete: false,
      IsEditable: true,
      ModifyBy: 1,
    });
    setMessageError(false);
    setEditMenuId(null);
    setOpenDialog(false);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: inputValue,
    });
  };

  const handleSubmit = async () => {
    if (formData.Name === "" || formData.Link === "") {
      // setMessageError(true);
      console.log(formData);
    } else {
      try {
        const response = await updateSubMenu(formData, editMenuId);
        // if (!response.ok) {
        //   throw new Error("Network response was not ok");
        // }
        // Handle success response
        console.log(response);
        // Optionally, you can fetch updated data from the server and update state
        closeEditForm();
      } catch (error) {
        console.error("Error updating menu:", error);
      }

      setFormData({
        Menu_Name: "",
        Link: "#",
        IsActive: true,
        IsDelete: false,
        IsEditable: false,
        ModifyBy: 1,
      });
      setOpenDialog(false);
      setMessageError(false);
    }
  };

  return (
    <React.Fragment>
      {props.subMenuData ? (
        <div
          className="dashboard-component-container"
          style={{ marginTop: "10px" }}
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">ID</TableCell>
                  <TableCell align="center">SubMenu Name</TableCell>
                  <TableCell align="center">Link</TableCell>
                  <TableCell align="center">Active</TableCell>
                  <TableCell align="center">Created On</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.subMenuData.Sub_Menu.map((row) => (
                  <TableRow
                    key={row.SubMenu_Id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {row.SubMenu_Id}
                    </TableCell>
                    <TableCell align="left">{row.Name}</TableCell>
                    <TableCell align="center">{row.Link}</TableCell>
                    <TableCell align="center">
                      {row.IsActive.toString()}
                    </TableCell>
                    <TableCell align="center">
                      {row.CreatedOn.toString()}
                    </TableCell>
                    <TableCell align="center">
                      <button
                        className="Edit-Button"
                        onClick={() => editData(row.SubMenu_Id)}
                      >
                        <FaPen></FaPen>
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog open={openDialog} onClose={closeEditForm} className="Dialog">
            <DialogTitle>Edit Menu ID: {editMenuId}</DialogTitle>
            <DialogContent className="DialogContent">
              <form className="form">
                <div className="formGroup">
                  <TextField
                    id="Name"
                    label="Sub Menu Name"
                    name="Name"
                    value={formData.Name}
                    onChange={handleInputChange}
                    variant="outlined"
                    style={{ marginTop: "5px", width: "100%" }}
                  />
                  <TextField
                    id="Link"
                    label="Link"
                    name="Link"
                    value={formData.Link}
                    onChange={handleInputChange}
                    variant="outlined"
                    style={{ marginTop: "5px", width: "100%" }}
                  />
                  <FormControlLabel
                    label="Active"
                    style={{ marginTop: "5px" }}
                    control={
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        id="isActive"
                        name="IsActive"
                        checked={formData.IsActive}
                        onChange={handleInputChange}
                      />
                    }
                  />

                  {messageError ? (
                    <p className="errorMessage">
                      Please enter all the required fields
                    </p>
                  ) : (
                    <p></p>
                  )}
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeEditForm}>Cancel</Button>
              <Button onClick={handleSubmit}>Save </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <div
        // className="dashboard-component-container"
        // style={{
        //   marginTop: "10px",
        //   height: "50px",
        //   // color: "var(--color-primary)",
        //   display: "flex",
        //   alignItems: "center",
        //   padding:'10px',
        //   justifyContent:'center'
        // }}
        >
          {/* Please select a Menu */}
        </div>
      )}
    </React.Fragment>
  );
}

export default Details;
