import React from "react";
import NoInternetConnection from "./pages/NoInternetConnection/Page-404";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { authProtectedRoutes, publicRoutes } from "./routes";
import {publicRoutes } from "./routes";
import Authmiddleware from "./routes/route";
import NonAuthLayout from "./layouts/NonAuthLayout";
import "./App.scss";
// import Login from "./pages/Authentication/Login";
import Dashboard from "./pages/Dashboard/index";
import Home from "./pages/Dashboard/Home/home";
import Pages from "./pages/Dashboard/Pages/Pages";
import Menu from "./pages/Dashboard/Menu/Menu";
import SubMenu from "./pages/Dashboard/Menu/Submenu";
import Slider from "./pages/Dashboard/Slider/Slider"
import SliderType from "./pages/Dashboard/Slider/SliderType"
import Form from "./pages/Dashboard/Form/Form"
import Category from "./pages/Dashboard/AR-Portal/Category"
import Product from "./pages/Dashboard/AR-Portal/Product"
import Profile from "./pages/Dashboard/Admin/Profile"
import HomePage from "./pages/Dashboard/Pages/HomePage";
import History from "./pages/Dashboard/Pages/History";
import Leadership from "./pages/Dashboard/Pages/Leadership";
import Award from "./pages/Dashboard/Pages/Award";
import HomeTextiles from "./pages/Dashboard/Pages/HomeTextiles";
import DyedPrinted from "./pages/Dashboard/Pages/DyedPrinted";
import MattressTicking from "./pages/Dashboard/Pages/MattressTicking";
import DropProofFabric from "./pages/Dashboard/Pages/DropProofFabric";
import Institutional from "./pages/Dashboard/Pages/Institutional";
import Weaving from "./pages/Dashboard/Pages/Weaving";
import Processing from "./pages/Dashboard/Pages/Processing";
import Stitching from "./pages/Dashboard/Pages/Stitching";
function App() {
  return (
    <React.Fragment>
      <Router>
        {/* <NoInternetConnection> */}
          <Routes>
            {publicRoutes.map((route, idx) => (
              <Route path={route.path} element={<NonAuthLayout>{route.component}</NonAuthLayout>} key={idx} exact={true} />
            ))}
            <Route path="/" element={<Authmiddleware> <Dashboard /> </Authmiddleware>} exact={true}></Route>

            <Route path="/dashboard" element={<Authmiddleware> <Dashboard /> </Authmiddleware>} exact={true} >
              <Route path="" element={<Home></Home>}></Route>
              <Route path="pages" element={<Pages></Pages>}>
                <Route path="home" element={<HomePage></HomePage>}></Route>
                <Route path="history" element={<History></History>}></Route>
                <Route path="leadership" element={<Leadership></Leadership>}></Route>
                <Route path="Awards" element={<Award></Award>}></Route>
                <Route path="home-textiles" element={<HomeTextiles></HomeTextiles>}></Route>
                <Route path="dyed-printed-fabric" element={<DyedPrinted></DyedPrinted>}></Route>
                <Route path="mattress-ticking" element={<MattressTicking></MattressTicking>}></Route>
                <Route path="drop-proof-fabric" element={<DropProofFabric></DropProofFabric>}></Route>
                <Route path="institutional-fabric-and-made-ups" element={<Institutional></Institutional>}></Route>
                <Route path="weaving-knitting" element={<Weaving></Weaving>}></Route>
                <Route path="processing-finishing" element={<Processing></Processing>}></Route>
                <Route path="stitching" element={<Stitching></Stitching>}></Route>
              </Route>
              <Route path="menu" element={<Menu></Menu>}></Route>
              <Route path="sub-menu" element={<SubMenu></SubMenu>}>
                
              </Route>
              <Route path="slider-types" element={<SliderType></SliderType>}></Route>
              <Route path="slider" element={<Slider></Slider>}></Route>
              <Route path="form" element={<Form></Form>}></Route>
              <Route path="category" element={<Category></Category>}></Route>
              <Route path="product" element={<Product></Product>}></Route>
              <Route path="profile" element={<Profile></Profile>}></Route>
            </Route>
          </Routes>
        {/* </NoInternetConnection> */}
      </Router>
    </React.Fragment>
  );
}

export default App;
